import { createAsyncThunk } from '@reduxjs/toolkit';

import { EventService } from '../services/ApiService';
import { errorToast, successToast } from '../state/notifications';

const tSuccessMessageUpdateMeetingTime = 'Updated Event!';
const tErrorMessageUpdateMeetingTime = 'Failed to update Event';
const tDefaultErrorMessage = 'Whoops! Sorry about that. Please try again.';
const tRsvpSucessMessage = 'Successfully rsvped!';
const tUnRsvpSuccessMessage = 'Successfully un-rsvped!';

export const updateMeetingTime = createAsyncThunk(
  'sessions/updateMeetingTime',
  async (body: Parameters<(typeof EventService)['meetingTime2']>[0], { dispatch }) => {
    try {
      await EventService.meetingTime2(body);
      dispatch(successToast({ message: tSuccessMessageUpdateMeetingTime }));
      return await EventService.event({ eventId: body!.eventId! });
    } catch (exception: unknown) {
      window.console.error(exception);
      dispatch(errorToast({ message: tErrorMessageUpdateMeetingTime }));
    }
  }
);

export const requestMembership = createAsyncThunk(
  'app/requestMembershipSession',
  async (body: { eventId: string; userId: string }, { dispatch }) => {
    try {
      await EventService.request(body);
      // updated pending request Ids are in this heavy data fetching endpoint
      const ccEvent = await EventService.event({ eventId: body.eventId });
      if (ccEvent.isPublic) {
        dispatch(successToast({ message: 'Event joined successfully!' }));
      } else {
        dispatch(successToast({ message: 'Event request sent successfully!' }));
      }
      return ccEvent;
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const addMember = createAsyncThunk(
  'app/addMembershipSession',
  async (body: { eventId: string; userId: string }, { dispatch }) => {
    try {
      await EventService.add(body);
      dispatch(successToast({ message: 'Member added successfully!' }));
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const rejectMember = createAsyncThunk(
  'app/rejectMembershipSession',
  async (body: { roomId: string; eventId: string; userId: string }, { dispatch }) => {
    try {
      await EventService.deny(body);
      dispatch(successToast({ message: 'Member request rejected.' }));
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const removeMember = createAsyncThunk(
  'app/removeMemberSession',
  async (body: { eventId: string; userId: string }, { dispatch }) => {
    try {
      await EventService.remove(body);
      dispatch(successToast({ message: 'Member removed successfully!' }));
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const addFacilitator = createAsyncThunk(
  'app/addFacilitatorEvent',
  async (body: { eventId: string; userId: string }, { dispatch }) => {
    try {
      await EventService.facilitator(body);
      dispatch(successToast({ message: 'Facilitator added!' }));
    } catch (exception: unknown) {
      window.console.log('exception: ', JSON.stringify(exception, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const removeFacilitator = createAsyncThunk(
  'app/removeFacilitatorEvent',
  async (body: { eventId: string; userId: string }, { dispatch }) => {
    try {
      await EventService.facilitator1(body);
      dispatch(successToast({ message: 'Facilitator permissions removed successfully.' }));
    } catch (exception: unknown) {
      window.console.log('exception: ', JSON.stringify(exception, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const leave = createAsyncThunk(
  'app/leaveSession',
  async (body: { eventId: string; userId: string }, { dispatch }) => {
    try {
      await EventService.leave(body);
      dispatch(successToast({ message: 'Left event successfully!' }));
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

export const rsvp = createAsyncThunk(
  'meeting/rsvp',
  async (body: { eventId: string; meetingId: string }, { dispatch }) => {
    try {
      await EventService.rsvp(body);
      dispatch(successToast({ message: tRsvpSucessMessage }));
    } catch (error) {
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
      dispatch(errorToast({ message: tDefaultErrorMessage }));
    }
  }
);

/* delete */
export const deleteEvent = createAsyncThunk(
  'sessions/deleteEvent',
  async (params: { eventId: string }, { dispatch }) => {
    await EventService.event1(params);
    dispatch(successToast({ message: 'Event deleted successfully!' }));
    return { eventId: params.eventId };
  }
);

export const deleteRsvp = createAsyncThunk(
  'meeting/deleteRsvp',
  async (body: { eventId: string; meetingId: string }, { dispatch }) => {
    try {
      await EventService.rsvp1(body);
      dispatch(successToast({ message: tUnRsvpSuccessMessage }));
    } catch (error) {
      dispatch(errorToast({ message: tDefaultErrorMessage }));
      window.console.log('error: ', JSON.stringify(error, undefined, 2));
    }
  }
);

type SaveEventPictureParams = {
  eventId: string;
  formFile: File;
};

export const saveEventPicture = createAsyncThunk('app/saveEventPicture', async (body: SaveEventPictureParams) => {
  await EventService.picture(body);
  return { eventId: body.eventId };
});
