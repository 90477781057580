import { Print } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useMemo } from 'react';

import { usePrint } from '~hooks/usePrint';
import { Tabs, TabsProps } from '~ui/atoms/Tabs/Tabs';
import { UserHistory, UserHistoryProps } from '~ui/molecules/UserHistory/UserHistory';

import Profile from '../../../components/Profiles/Profile';
import { filterBoolean } from '../../../utilities/filterPredicates';
import { ResumePreview, ResumePreviewProps } from '../ResumeBuilderTemplate/ResumePreview';

function Resume(props: UserProfileTemplateProps['resumePreviewProps']) {
  const { onPrint, portal } = usePrint(
    <ResumePreview
      {...props}
      slotProps={{
        ...props.slotProps,
        container: { ...props.slotProps?.container, sx: { ...props.slotProps?.container?.sx, border: 'unset' } },
      }}
    />
  );

  return (
    <Box sx={{ display: 'grid', gap: '0.5rem', gridAutoRows: 'auto 1fr' }}>
      <Box>
        <Button disableRipple onClick={onPrint} size="large" startIcon={<Print fontSize="large" />} variant="text">
          Print
        </Button>
        {portal}
      </Box>
      <ResumePreview {...props} />
    </Box>
  );
}

export type UserProfileTemplateBaseProps = {
  resumePreviewProps: ResumePreviewProps;
  userHistoryProps: UserHistoryProps;
  flags?: Record<'showResume' | 'showUserHistory', boolean | undefined>;
};
export type UserProfileTemplateSlotProps = { slotProps?: TabsProps['slotProps'] };
export type UserProfileTemplateProps = UserProfileTemplateBaseProps & UserProfileTemplateSlotProps;

export function UserProfileTemplate({
  flags,
  resumePreviewProps,
  userHistoryProps,
  ...props
}: UserProfileTemplateProps) {
  const { tabs } = useMemo(
    function () {
      return {
        tabs: [
          { component: <Profile />, label: 'Profile' },
          Boolean(flags?.showResume) && { component: <Resume {...resumePreviewProps} />, label: 'Resume' },
          Boolean(flags?.showUserHistory) && { component: <UserHistory {...userHistoryProps} />, label: 'History' },
        ].filter(filterBoolean),
      };
    },
    [flags]
  );

  return (
    <Tabs
      options={{ syncWithSearchParams: true }}
      slotProps={{ contentContainer: { padding: '1.25rem 1rem' } }}
      tabs={tabs}
      {...props}
    />
  );
}
