import { UserHistoryDto } from '@crowdcoursing/api/schemas';
import { Box, BoxProps, Typography } from '@mui/material';

import { RenderStates } from '~ui/atoms/RenderStates/RenderStates';

import { UserHistoryEvents } from './UserHistoryEvents';
import { UserHistoryRooms } from './UserHistoryRooms';
import { type UserHistoryStatsProps, UserHistoryVolunteerStats } from './UserHistoryStats';
import { UserHistoryVolunteerRecords } from './UserHistoryVolunteers';

const tUserHistoryError = 'An error occurred while retrieving user history';
const tNoUserHistoryFound = 'No user history found';
const tLoading = 'Loading';

function Empty(props: BoxProps) {
  return (
    <Box data-testid="UserHistory-empty" {...props}>
      <Typography sx={{ color: (theme) => theme.palette.text.disabled }}>{tNoUserHistoryFound}</Typography>
    </Box>
  );
}

function Error(props: BoxProps) {
  return (
    <Box data-testid="UserHistory-error" {...props}>
      <Typography sx={{ color: (theme) => theme.palette.text.disabled }}>{tUserHistoryError}</Typography>
    </Box>
  );
}

function Pending(props: BoxProps) {
  return (
    <Box data-testid="UserHistory-pending" {...props}>
      <Typography sx={{ color: (theme) => theme.palette.text.disabled }}>{tLoading}</Typography>
    </Box>
  );
}

export type UserHistoryBaseProps = {
  data?: UserHistoryDto;
  showVolunteerStats?: boolean;
  status: Utils.QueryStatus;
};
export type UserHistorySlotProps = { slotProps?: { container?: BoxProps; stats?: UserHistoryStatsProps['slotProps'] } };
export type UserHistoryProps = UserHistoryBaseProps & UserHistorySlotProps;

export function UserHistory({ data, showVolunteerStats = true, status, slotProps }: UserHistoryProps) {
  return (
    <RenderStates
      data={data}
      empty={<Empty {...slotProps?.container} />}
      error={<Error {...slotProps?.container} />}
      pending={<Pending {...slotProps?.container} />}
      status={status}
      success={function ({ volunteerStats, rooms, events }) {
        return (
          <Box
            data-testid="UserHistory"
            {...slotProps?.container}
            sx={{ display: 'grid', gap: '2rem', ...slotProps?.container?.sx }}
          >
            {showVolunteerStats && (
              <>
                <UserHistoryVolunteerStats slotProps={slotProps?.stats} volunteerStats={volunteerStats ?? {}} />
                <UserHistoryVolunteerRecords data={data?.volunteeringRecords ?? []} userId={data?.id ?? ''} />
              </>
            )}
            <UserHistoryRooms rooms={rooms ?? []} />
            <UserHistoryEvents events={events ?? []} />
          </Box>
        );
      }}
    />
  );
}
