import { Location } from 'react-router-dom';
import { Navigate, useLocation } from 'react-router-dom';

import { Route } from '~services/router/Route';

export const RedirectToLogin = () => {
  const location = useLocation();
  const target: Location = {
    ...location,
    pathname: Route.login(),
  };
  const state = location.pathname !== Route.index() && { from: location };

  return <Navigate state={state} to={target} />;
};
