import { TextField, TextFieldValue } from '../../../atoms/Form';
import { ConstructedTextField } from '../types';

export const FIELD_NAME_PHONE = 'phone';

const tLabel = 'Phone';

const tRequired = 'Phone required';
const tFormatPhone = 'Phone must be of format "(123) 456-7890"';
const tPlaceholder = '(123) 456-7890';

export type PhoneProps = ConstructedTextField;

export const validatePhoneNumber = (number: string): boolean => {
  // Check for exactly 10 digits
  const cleaned = number.replace(/\D/g, '');
  return cleaned.length === 10;
};

export const formatPhoneNumber = (value: string): string => {
  // Strip all non-numeric characters
  const numbers = value.replace(/\D/g, '').slice(0, 10);

  if (numbers.length === 0) return '';

  // Add area code
  let formatted = '';
  if (numbers.length > 0) {
    formatted += `(${numbers.slice(0, 3)}`;
  }

  // Add first part of local number
  if (numbers.length > 3) {
    formatted += `) ${numbers.slice(3, 6)}`;
  }

  // Add second part of local number
  if (numbers.length > 6) {
    formatted += `-${numbers.slice(6, 10)}`;
  }

  return formatted;
};

export const Phone = ({ textFieldProps, ...props }: PhoneProps) => {
  return (
    <TextField
      helperText={tFormatPhone}
      name={FIELD_NAME_PHONE}
      parse={(value: TextFieldValue) => formatPhoneNumber(value || '')}
      textFieldProps={{
        label: tLabel,
        placeholder: tPlaceholder,
        ...props,
        ...textFieldProps,
      }}
      validate={(value, _, meta) => {
        if (props.required && meta?.modified && !value) {
          return tRequired;
        }

        if (meta?.dirty && value) {
          if (!validatePhoneNumber(value)) {
            return tFormatPhone;
          }
        }

        return '';
      }}
    />
  );
};
