import { Alert, Card, CardContent, Grid, styled, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Route } from '~services/router/Route';

import AddMemberButton from './AddMemberButton';
import { MemberList, MemberListProps } from './MemberList';
import { RequestList, RequestListProps } from './RequestList';
import { sortAlphabetical, sortMembers } from './utils';

const tPendingRequests = 'Pending Requests';

const PAPER_ELEVATION = 2;
const tPendingUsersSubtext = 'These are people awaiting your approval.';
const tRoomMembersSubtext = 'These people have been approved.';

const StyledTypography = styled(Typography)({ textDecoration: 'none' }) as typeof Typography;

const RequestListWrapper = ({ ...props }: RequestListProps) => {
  const hasRequests = props.requests.length > 0;

  return (
    <Grid hidden={!hasRequests}>
      <RequestList {...props} />
    </Grid>
  );
};

export interface MemberManagementProps extends RequestListProps, MemberListProps {
  heading: string;
  subHeading: string | undefined;
  roomId?: string;
  eventId?: string;
}

export const MemberManagement = ({
  isOwner,
  heading,
  subHeading,
  members,
  requests,
  roomId,
  eventId,
  onApprove,
  onAddFacilitator,
  onAddOwner,
  onRemoveFacilitator,
  onReject,
  onRemove,
  onClick,
}: MemberManagementProps) => {
  const sortedMembers = useMemo(() => [...members].sort(sortMembers), [members]);
  const sortedRequests = useMemo(() => [...requests].sort(sortAlphabetical), [requests]);

  const pendingUsers = sortedRequests;
  const facilitators = sortedMembers.filter((a) => a.isFacilitator);
  const approvedMembers = sortedMembers.filter((a) => !a.isFacilitator);

  const requestListWrapperProps: RequestListProps = {
    onApprove,
    onReject,
    requests: pendingUsers,
  };

  const sharedProps: Omit<MemberListProps, 'members'> = {
    isOwner,
    onAddFacilitator,
    onAddOwner,
    onClick,
    onRemove,
    onRemoveFacilitator,
  };

  const memberListProps: MemberListProps = { ...sharedProps, members: approvedMembers };
  const facilitatorListProps: MemberListProps = { ...sharedProps, members: facilitators };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid id="member-management-page" item>
        <Grid item xs={12}>
          <Typography variant={'h3'}>{heading}</Typography>
        </Grid>
        {roomId && (
          <StyledTypography component={Link} to={Route.podView({ roomId })} variant={'h5'}>
            {subHeading}
          </StyledTypography>
        )}
        {!roomId && (
          <Grid xs={12}>
            <StyledTypography variant={'h5'}>{subHeading}</StyledTypography>
          </Grid>
        )}
        <Grid item marginTop="1em" xs={12}>
          {roomId && <AddMemberButton roomId={roomId} />}
          {eventId && <AddMemberButton eventId={eventId} />}
        </Grid>
        <Grid data-testid="request-list" item marginTop="2em">
          <Card data-testid="pending-request-header" elevation={PAPER_ELEVATION} sx={{ padding: '1rem' }}>
            <CardContent>
              <Grid>
                <Typography variant="h4">{tPendingRequests}</Typography>
              </Grid>
              <Grid item marginTop=".5em">
                <Alert severity="warning">{tPendingUsersSubtext}</Alert>
              </Grid>
              <Grid item marginTop="1em">
                {pendingUsers.length > 0 && <RequestListWrapper {...requestListWrapperProps} />}
                {pendingUsers.length === 0 && (
                  <Grid container>
                    <Typography>All clear. Good to go!</Typography>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid data-testid="member-list" item marginTop="2em" xs={12}>
          <Card elevation={PAPER_ELEVATION} sx={{ padding: '1rem' }}>
            <CardContent>
              <Grid item>
                <Typography variant="h4">Members</Typography>
              </Grid>
              <Grid item marginTop=".5em">
                <Alert severity="success">{tRoomMembersSubtext}</Alert>
              </Grid>
              <Grid data-testid="member-list" item marginTop="1em">
                {facilitators.length > 0 && <MemberList {...facilitatorListProps} />}
                {approvedMembers.length > 0 && <MemberList {...memberListProps} />}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};
