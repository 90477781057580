import { useFeature } from '../hooks/useFeature';
import { Feature } from '../state/features';

interface FeatureGuardProps {
  children?: React.ReactNode;
  feature: Feature;
}

/** renders children only if the given feature is enabled */
export function FeatureGuard({ feature, children }: FeatureGuardProps) {
  const enabled = useFeature(feature);
  if (!enabled) {
    return null;
  }

  return <>{children}</>;
}
