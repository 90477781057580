import { AddCircle as AddIcon } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Route } from '~services/router/Route';

import { deleteRsvp, rsvp, updateMeetingTime } from '../../actions/events';
import type { MeetingTime as CCUIMeetingTime } from '../../cc-ui';
import { Dialog, MeetingTimes } from '../../cc-ui';
import { useConditionalTooltip } from '../../cc-ui/hooks';
import { useDispatch, useSelector } from '../../hooks';
import { GetSessionRulesQuery, useGetSessionRulesQuery } from '../../services/graphql/generated/react-query';
import { userRoleSelector } from '../../services/redux/selectors';
import { getIsInPast } from '../../utilities/utils';

const tCancel = 'Cancel';
const tAddEventTime = 'Add Event Time';
const tAdd = 'Add';
const tNoSeries = 'No series available';
const tCreateEventTimesButton = 'Create Event Times';
const tRsvp = 'Rsvp';

const tConfirm = 'Confirm';
const tDialogTitle = 'Event rules';
const tBody = 'Enter "confirm" in the text field below if have read and understand the rules for this event.';

export interface Series {
  id?: string | null;
  roomId?: string;
  name?: string;
  description?: string;
}

export interface MeetingTime extends Omit<CCUIMeetingTime, 'rsvpCheckbox' | 'tags'> {
  id: string;
  eventId: string;
  seriesId?: string | null;
  userHasRsvpd: boolean;
  isFilled: boolean;
}

export interface Event {
  id: string;
  bubbleId: string;
  userIsFacilitator: boolean;
  userIsMember: boolean;
  meetingTimes: MeetingTime[];
}

interface SeriesChipProps {
  isSelected?: boolean;
  series: Series;
  slotProps?: {
    chip: React.ComponentProps<typeof Chip>;
  };
}

const SeriesChip = ({ isSelected, slotProps, series }: SeriesChipProps) => {
  const { ref, disableListeners } = useConditionalTooltip(series.description ? Boolean(series.description) : undefined);

  return (
    <Tooltip ref={ref} title={<>{series.description}</>} {...disableListeners}>
      <Chip
        data-testid="series-chip"
        label={series.name}
        {...slotProps?.chip}
        sx={{
          '&:hover': { backgroundColor: (theme) => (isSelected ? theme.palette.secondary.light : 'default') },
          backgroundColor: (theme) => (isSelected ? theme.palette.secondary.main : 'default'),
          ...slotProps?.chip?.sx,
        }}
      />
    </Tooltip>
  );
};

interface SeriesChipContainerProps {
  meetingTime: MeetingTime;
  event: Event;
  isMember: boolean;
  series: Series[];
  /** callback for if we changed data */
  refetch: () => void;
}

const Link = styled(RouterLink)({ color: 'inherit', textDecoration: 'none' });

const SeriesChipContainer = ({
  meetingTime: { id: meetingTimeId, seriesId, beginMeetingTime, endMeetingTime },
  event: { id: eventId },
  series: allSeries,
  refetch,
}: SeriesChipContainerProps) => {
  const dispatch = useDispatch();
  const userRole = useSelector(userRoleSelector);
  const series = allSeries.find(({ id }) => id === seriesId);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const setDialogOpen = useCallback(() => setIsDialogOpen(true), []);
  const setDialogClose = useCallback(() => {
    setIsDialogOpen(false);
    setSelectedSeries(undefined);
  }, []);
  const [selectedSeries, setSelectedSeries] = useState<Series | undefined>(undefined);

  const handleClickAdd = useCallback(async () => {
    if (selectedSeries) {
      await dispatch(
        updateMeetingTime({
          body: {
            beginMeetingTime: new Date(beginMeetingTime!),
            endMeetingTime: new Date(endMeetingTime!),
            meetingTimeId: meetingTimeId!,
            meetingTimeSeriesId: selectedSeries.id ?? undefined,
          },
          eventId: eventId!,
          meetingId: meetingTimeId!,
        })
      );
      refetch(); // TODO: figure out graphql mutations so it automatically refreshes affected data
      setDialogClose();
    }
  }, [meetingTimeId, eventId, seriesId, selectedSeries]);

  const onDelete = useCallback(async () => {
    await dispatch(
      updateMeetingTime({
        body: {
          beginMeetingTime: new Date(beginMeetingTime!),
          endMeetingTime: new Date(endMeetingTime!),
          meetingTimeId,
          meetingTimeSeriesId: undefined,
        },
        eventId: eventId!,
        meetingId: meetingTimeId!,
      })
    );
    refetch(); // TODO: figure out graphql mutations so it automatically refreshes affected data
  }, [meetingTimeId, eventId]);

  if (userRole === 'owner' || userRole === 'facilitator') {
    if (!series) {
      return (
        <>
          <IconButton data-testid="add-series-button" onClick={setDialogOpen} sx={{ margin: '-8px 0' }}>
            <AddIcon />
          </IconButton>
          <MuiDialog open={isDialogOpen}>
            <DialogTitle>{tAddEventTime}</DialogTitle>
            <DialogContent>
              {!allSeries && <>{tNoSeries}</>}
              {allSeries?.map((seriesElement) => (
                <SeriesChip
                  isSelected={selectedSeries?.id === seriesElement.id}
                  key={seriesElement.id}
                  series={seriesElement}
                  slotProps={{ chip: { onClick: () => setSelectedSeries(seriesElement) } }}
                />
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={setDialogClose} variant="text">
                {tCancel}
              </Button>
              <Button data-testid="add-series-confirm-button" disabled={!selectedSeries} onClick={handleClickAdd}>
                {tAdd}
              </Button>
            </DialogActions>
          </MuiDialog>
        </>
      );
    }

    return <SeriesChip series={series} slotProps={{ chip: { onDelete } }} />;
  }

  return series && <SeriesChip series={series} />;
};

const Rules = ({ rules }: { rules: NonNullable<GetSessionRulesQuery['session']>['rules'] }) => {
  return (
    <List>
      {rules
        .sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          }

          if (a.order < b.order) {
            return -1;
          }

          return 0;
        })
        .map(({ description, name }, index) => {
          return (
            <ListItem alignItems="flex-start" disableGutters key={name}>
              <ListItemIcon sx={{ marginTop: description ? '0.33rem' : '0.25rem', minWidth: '2rem' }}>
                <Typography fontWeight="bold">{index + 1}</Typography>
              </ListItemIcon>
              <ListItemText primary={name} secondary={description} />
            </ListItem>
          );
        })}
    </List>
  );
};

interface MeetingTimesContainerProps {
  isFacilitator: boolean;
  event: Event;
  series: Series[];
  /** callback for if we changed data */
  refetch: () => void;
}

const isSelectedMap =
  (
    event: Event,
    series: Series[],
    onClickRow: (isInPast: boolean, isSelected: boolean, meetingId: string) => void,
    refetch: () => void
  ) =>
  // eslint-disable-next-line react/display-name
  (meetingTime: MeetingTime) => {
    const isInPast = getIsInPast(meetingTime.beginMeetingTime!);
    const isSelected = meetingTime.userHasRsvpd;
    const handleClickCheck = () => {
      onClickRow(isInPast, isSelected, meetingTime.id ?? '');
    };

    return {
      ...meetingTime,
      isSelected,
      rsvpCheckbox: (
        <Checkbox
          checked={isSelected}
          data-testid="rsvp-checkbox"
          disabled={
            (!event.userIsFacilitator && !event.userIsMember) || (meetingTime.isFilled && !meetingTime.userHasRsvpd)
          }
          onClick={handleClickCheck}
          sx={{ margin: '-8px 0' }}
        />
      ),
      tags: (
        <SeriesChipContainer
          event={event}
          isMember={event.userIsMember}
          meetingTime={meetingTime}
          refetch={refetch}
          series={series}
        />
      ),
    };
  };

const MeetingTimesContainer = ({ isFacilitator, event, series, refetch }: MeetingTimesContainerProps) => {
  const dispatch = useDispatch();
  const [requestBody, setRequestBody] = useState<Partial<Parameters<typeof rsvp>[0]>>({});
  const [confirmValue, setConfirmValue] = useState('');
  const [open, setOpen] = useState(false);

  const { data } = useGetSessionRulesQuery({ eventId: event.id! });

  const handleClickCancel = useCallback(() => {
    setOpen(false);
    setRequestBody({});
    setConfirmValue('');
  }, [setConfirmValue, setRequestBody, setOpen]);

  const onClickRow = async (isInPast: boolean, isSelected: boolean, meetingId: string) => {
    if ((event.userIsMember || event.userIsFacilitator) && !isInPast) {
      const body = { eventId: event.id!, meetingId };
      if (isSelected) {
        await dispatch(deleteRsvp(body));
        refetch(); // TODO: figure out graphql mutations so apollo automatically refreshes affected data
        return;
      }

      if (!data?.session?.rules.length) {
        await dispatch(rsvp(body));
        refetch();
        return;
      }

      setRequestBody(body);
      setOpen(true);
    }
  };

  const handleChangeConfirm = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setConfirmValue(event.target.value);
    },
    [setConfirmValue]
  );

  const handleClickConfirm = async () => {
    const { meetingId, eventId } = requestBody;
    if (!(meetingId && eventId)) {
      throw new Error(
        `(MeetingTimesContainer)(handleClickConfirm): Expected requestBody to contain meetingId, roomId, eventId. Received "${requestBody}`
      );
    }

    if ((confirmValue ?? '').toLowerCase() !== 'confirm') {
      return;
    }

    await dispatch(rsvp(requestBody as Parameters<typeof rsvp>[0]));
    handleClickCancel();
    refetch();
  };

  if (isFacilitator && (!event.meetingTimes || event.meetingTimes.length == 0)) {
    return (
      <Grid container>
        <Grid item marginTop="1em" xs={12}>
          <Typography>No Event Times</Typography>
        </Grid>
        <Grid item marginTop="1em" xs={12}>
          <Link sx={{ display: 'block' }} to={Route.eventEdit({ eventId: event.id! })}>
            <Button data-testid="add-event-times-button">{tCreateEventTimesButton}</Button>
          </Link>
        </Grid>
      </Grid>
    );
  }

  if (!isFacilitator && (!event.meetingTimes || event.meetingTimes.length == 0)) {
    return (
      <Grid container>
        <Grid item marginTop="1em" xs={12}>
          <Typography>No Event Times</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <MeetingTimes
        eventId={event.id!}
        isFacilitator={isFacilitator}
        meetingTimes={event.meetingTimes?.map(isSelectedMap(event, series, onClickRow, refetch)) ?? []}
      />
      <Dialog
        actions={[
          <Button key="cancel" onClick={handleClickCancel} variant="text">
            {tCancel}
          </Button>,
          <Button disabled={(confirmValue ?? '').toLowerCase() !== 'confirm'} key="submit" onClick={handleClickConfirm}>
            {tRsvp}
          </Button>,
        ]}
        dialogType="custom"
        onDialogClose={handleClickCancel}
        open={open}
        title={tDialogTitle}
      >
        <Box display="grid" gap="1rem">
          <Rules rules={data?.session?.rules ?? []} />
          <Typography fontStyle="italic">{tBody}</Typography>
          <TextField
            onChange={handleChangeConfirm}
            placeholder={tConfirm}
            sx={{ width: 'fit-content' }}
            value={confirmValue}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default MeetingTimesContainer;
