import { useResumeGetUserResume, useResumeUpsertUserResume } from '@crowdcoursing/api/components';
import { queryKeyFn } from '@crowdcoursing/api/context';
import { ResumeDto } from '@crowdcoursing/api/schemas';
import { useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import deepEqual from 'fast-deep-equal';
import { FORM_ERROR } from 'final-form';
import { useMemo } from 'react';
import { type Primitive } from 'type-fest';

import { useSelector } from '~hooks/useTypedRedux';
import { ResumeBuilderTemplateProps } from '~ui/templates/ResumeBuilderTemplate/ResumeBuilderTemplate';

type FormValues = ResumeBuilderTemplateProps['initialValues'];

type NullSanitized<T> = T extends (infer U)[]
  ? NullSanitized<U>[]
  : T extends object
  ? { [Key in keyof T]: NullSanitized<T[Key]> }
  : T extends Primitive
  ? Exclude<T, null>
  : T extends null
  ? undefined
  : never;

// ?: does it make sense to remove `null` from all incoming DTOs?
function sanitizeNull<T extends unknown | object | object[] | Primitive | Primitive[]>(value: T): NullSanitized<T>;
function sanitizeNull<T>(value: T) {
  if (value === null) {
    return undefined;
  }

  if (typeof value !== 'object') {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map(sanitizeNull);
  }

  return Object.fromEntries(Object.entries(value).map(([k, v]) => [k, sanitizeNull(v)]));
}

function mapResumeDtoToFormValues(values?: ResumeDto): FormValues {
  if (!values) {
    return;
  }

  const { education, professionalExperiences, ...rest } = sanitizeNull(values);
  return {
    ...rest,
    ...(education?.length ? { education: education[0] } : {}),
    ...(professionalExperiences?.length
      ? {
          professionalExperiences: professionalExperiences.map(function ({ end, start, ...experience }) {
            return { ...experience, ...(end ? { end: dayjs(end) } : {}), ...(start ? { start: dayjs(start) } : {}) };
          }),
        }
      : {}),
  };
}

export function mapFormValuesToResumeDto(values?: FormValues): ResumeDto | undefined {
  if (!values) {
    return;
  }

  const { education, professionalExperiences, ...rest } = values;
  return {
    ...rest,
    ...(education && { education: [education] }),
    ...(professionalExperiences && {
      professionalExperiences: professionalExperiences.map(function ({ end, start, ...experience }) {
        return { ...experience, ...(end ? { end: end.toJSON() } : {}), ...(start ? { start: start.toJSON() } : {}) };
      }),
    }),
  };
}

function concat(a?: string, b?: string, separator = '') {
  if (a && b) {
    return `${a}${separator} ${b}`;
  }

  if (a) {
    return a;
  }

  if (b) {
    return b;
  }

  return undefined;
}

export function useResumeValues(options?: Parameters<typeof useResumeGetUserResume>[1]) {
  const user = useSelector(({ user }) => user);
  const userId = user?.id ?? 'USER_ID_NOT_FOUND';

  const { data, status } = useResumeGetUserResume(
    { pathParams: { userId } },
    {
      staleTime: 0,
      ...options,
      select(data) {
        return mapResumeDtoToFormValues(data);
      },
    }
  );

  const { initialValues: values, queryKey } = useMemo(
    function () {
      return {
        initialValues: {
          ...data,
          personalInformation: {
            email: user?.email,
            location: concat(user?.city, user?.state),
            name: concat(user?.firstName, user?.lastName),
            phone: user?.phoneNumber,
            ...data?.personalInformation,
          },
        },
        queryKey: queryKeyFn({
          operationId: 'resumeGetUserResume',
          path: '/api/Resume/user/{userId}',
          variables: { pathParams: { userId } },
        }),
      };
    },
    [user, data]
  );

  return { queryKey, status, userId, values };
}

type UseResumeBuilderReturn = {
  resumeBuilderTemplateProps: ResumeBuilderTemplateProps;
};

export function useResumeBuilder(): UseResumeBuilderReturn {
  const { values: initialValues, queryKey, status, userId } = useResumeValues();

  const queryClient = useQueryClient();
  const { mutate } = useResumeUpsertUserResume({
    onError(_error, variables, context) {
      queryClient.setQueryData<typeof variables.body>(
        queryKey,
        (context as { rollback: typeof variables.body }).rollback
      );
    },
    async onMutate(variables) {
      await queryClient.cancelQueries({ queryKey });
      const rollback = queryClient.getQueryData(queryKey);
      queryClient.setQueryData<typeof variables.body>(queryKey, function (current) {
        return { ...current, ...variables.body };
      });
      return { rollback };
    },
    onSettled() {},
  });

  return {
    resumeBuilderTemplateProps: {
      initialValues,
      onSubmit(values, form) {
        const formInitialValues = form.getState().initialValues;
        if (
          deepEqual(formInitialValues, values) ||
          Object.entries(formInitialValues).every(([k, v]) => deepEqual({ [k]: v }, { [k]: values[k] }))
        ) {
          return;
        }

        let result: Record<typeof FORM_ERROR, string> | undefined = undefined;
        mutate(
          { body: { ...mapFormValuesToResumeDto(values), userId } },
          {
            onError(error) {
              result = { [FORM_ERROR]: JSON.stringify(error, null, 2) };
            },
          }
        );
        return result;
      },
      status,
    },
  };
}
