import { Table, TableBody, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Route } from '~services/router/Route';

import { generateMeetingTime } from '../../../../utilities/utils';
import { TableCell } from '../TableCell';
import { TablePaginationActions } from '../TablePaginationActions';

const tName = 'Event';
const tBeginEnd = 'Event Time';
const tVolunteers = 'RSVPs';

const ROW_HEIGHT = 45.02;
const ROWS_PER_PAGE = 3;
const HEADERS = [tName, tBeginEnd, tVolunteers];

const getEmptyRows = (rows: any[], page: number, rowsPerPage: number) =>
  rows.length === 0 ? rowsPerPage : rowsPerPage - Math.min(rowsPerPage, Math.abs(rows.length - page * rowsPerPage));

const getPaginatedRows = (rows: any[], page: number, rowsPerPage: number) =>
  rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

export interface Rsvp {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}

const toRsvpName = (r: Rsvp) => {
  if (r.firstName && r.lastName) {
    return `${r.firstName} ${r.lastName}`;
  }
  if (r.lastName) {
    return r.lastName;
  }
  if (r.email) {
    return r.email;
  }
  return '<partial profile>';
};

export interface ScheduleMeetingTime {
  eventId: string;
  sessionName: string;
  beginMeetingTime: Date | string;
  endMeetingTime: Date | string;
  rsvps: Rsvp[];
}

export interface ScheduleProps {
  roomId: string;
  meetingTimes: ScheduleMeetingTime[];
  onClickRow: (eventId: string) => void;
}

export const Schedule = ({ meetingTimes, roomId }: ScheduleProps) => {
  const [page, setPage] = useState(0);
  const [emptyRows, setEmptyRows] = useState(0);
  const [paginatedRows, setPaginatedRows] = useState<ScheduleMeetingTime[]>([]);

  useEffect(() => {
    const updatedEmptyRows = getEmptyRows(meetingTimes, page, ROWS_PER_PAGE);
    setEmptyRows(updatedEmptyRows);

    const updatedPaginatedRows = getPaginatedRows(meetingTimes, page, ROWS_PER_PAGE);
    setPaginatedRows(updatedPaginatedRows);
  }, [meetingTimes, page, setPaginatedRows]);

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage);
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {HEADERS.map((header) => (
              <TableCell
                key={header}
                typographyProps={{ sx: { fontWeight: 'bold' }, variant: 'overline' }}
                value={header}
              />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.length > 0 &&
            paginatedRows.map(({ beginMeetingTime, endMeetingTime, rsvps, sessionName }, index) => (
              <TableRow component={Link} key={index} style={{ textDecoration: 'none' }} to={Route.podView({ roomId })}>
                <TableCell
                  tableCellProps={{ sx: { minWidth: '128px' } }}
                  typographyProps={{ variant: 'body2' }}
                  value={sessionName}
                />
                <TableCell
                  tableCellProps={{ sx: { minWidth: '128px' } }}
                  typographyProps={{ variant: 'body2' }}
                  value={generateMeetingTime(beginMeetingTime, endMeetingTime)}
                />
                <TableCell
                  typographyProps={{ variant: 'body2' }}
                  value={rsvps.length > 0 ? rsvps.map(toRsvpName).join(', ') : 'N/A'}
                />
              </TableRow>
            ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: ROW_HEIGHT * emptyRows }}>
              <TableCell tableCellProps={{ colSpan: 6 }} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              ActionsComponent={TablePaginationActions}
              colSpan={Number.MAX_SAFE_INTEGER}
              count={meetingTimes.length}
              onPageChange={handleChangePage}
              page={page}
              rowsPerPage={ROWS_PER_PAGE}
              rowsPerPageOptions={[]}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};
