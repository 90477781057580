import { lazy } from 'react';

/**
 * Asynchronously load a component (code splitting)
 * @example
 * export const ComponentAsync = asyncify(Component);
 */
export function asyncify<T extends () => JSX.Element>(component: T) {
  return lazy<T>(async function () {
    return new Promise<{ default: T }>((resolve) => {
      resolve({ default: component });
    });
  });
}
