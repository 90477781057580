import { Tooltip, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Route } from '~services/router/Route';

const tRoomLinkTooltip = 'Link to the room';

interface BreadcrumbsProps {
  roomId: string;
  name: string;
}

export const Breadcrumbs = ({ roomId, name }: BreadcrumbsProps) => {
  return (
    <Typography variant="h6">
      {'in '}
      <Tooltip placement="right-end" title={tRoomLinkTooltip}>
        <Link data-testid="open-room-link" style={{ textDecoration: 'none' }} to={Route.podView({ roomId })}>
          {name}
        </Link>
      </Tooltip>
    </Typography>
  );
};
