import { IterableElement } from 'type-fest';

import {
  AdminSelectQuery,
  CurrentDistrictStats,
  DistrictAdminSchoolsQuery,
  SchoolStatsQuery,
} from '../../../services/graphql/generated/react-query';
import { SchoolMembers } from '../../organisms/DataGrid';
import { AdminTemplate } from '../../templates/AdminTemplate/AdminTemplate';

export type School = IterableElement<AdminSelectQuery['mySchools']>;

export type District = IterableElement<AdminSelectQuery['mySchoolDistricts']>;

export const round = (value: number, precision: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const getNextGoal = (current: number) => {
  if (current < 10) {
    return '10';
  }
  if (current < 25) {
    return '25';
  }
  if (current < 50) {
    return '50';
  }
  if (current < 100) {
    return '100';
  }
  if (current < 500) {
    return '500';
  }
  if (current < 1000) {
    return '1,000';
  }
  if (current < 5000) {
    return '5,000';
  }
  if (current < 10000) {
    return '10,000';
  }
  if (current < 15000) {
    return '15,000';
  }
};

function toHoursAndMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return `${hours}h ${minutes}m`;
}

function numberToDollarsAndCents(amount: number): string {
  return amount.toLocaleString('en-US', {
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
  });
}

export const formatStat = ([key, value]: [string, number]) => {
  if (key.startsWith('minutes') && typeof value === 'number') {
    return {
      [key]: toHoursAndMinutes(value),
    };
  }
  if (key.startsWith('economic') && typeof value === 'number') {
    return {
      [key]: numberToDollarsAndCents(value),
    };
  }

  return { [key]: String(value) };
};

export function formatToMoney(amount: number, currency = 'USD', locale = 'en-US') {
  if (typeof amount !== 'number') {
    throw new Error('Input must be a number');
  }

  return new Intl.NumberFormat(locale, {
    currency,
    style: 'currency',
  }).format(amount);
}

export function formatToTwoDecimalPlaces(value: number | undefined): string {
  if (value === undefined) {
    return '0.00';
  }
  return value.toFixed(2);
}

export const formatMinuteStat = (value: number | undefined) => {
  if (value === undefined) {
    return toHoursAndMinutes(0);
  }
  return toHoursAndMinutes(value);
};

export const formatDistrictStats = (
  data?: CurrentDistrictStats
): Record<keyof Omit<NonNullable<typeof data>, '__typename' | 'name' | 'ageGroups'>, string> => {
  if (data) {
    return (Object.entries(data).filter(([key]) => key !== '__typename' && key !== 'name') as [string, number][])
      .map(formatStat)
      .reduce((accumulator, currentValue) => ({ ...accumulator, ...currentValue })) as ReturnType<
      typeof formatDistrictStats
    >;
  }

  return {
    backgroundCheckComplete: '0',
    backgroundCheckFailed: '0',
    backgroundCheckInProgress: '0',
    economicImpactAllTime: '0',
    economicImpactMonth: '0',
    economicImpactWeek: '0',
    economicImpactYTD: '0',
    genderDistribution: '0',
    minutesAllTime: '0',
    minutesScheduledThisNextYear: '0',
    minutesThisMonth: '0',
    minutesThisWeek: '0',
    minutesThisYear: '0',
    minutesUnconfirmed: '0',
    participatingEducators: '0',
    schoolCount: '0',
    schoolsWithMembersCount: '0',
    totalApprovedVolunteers: '0',
    totalEducators: '0',
    totalPendingVolunteers: '0',
    totalVolunteersMonth: '0',
    totalVolunteersWeek: '0',
    totalVolunteersYear: '0',
  };
};

export const formatSchoolStats = (
  data?: Omit<SchoolStatsQuery['schoolStats'], 'schoolName' | 'signIns' | 'ageGroups'>
): Record<keyof Omit<NonNullable<typeof data>, '__typename'>, string> => {
  if (data) {
    return (Object.entries(data).filter(([key]) => key !== '__typename') as [string, number][])
      .map(formatStat)
      .reduce((accumulator, currentValue) => ({ ...accumulator, ...currentValue })) as ReturnType<
      typeof formatSchoolStats
    >;
  }

  return {
    backgroundCheckComplete: '0',
    backgroundCheckFailed: '0',
    backgroundCheckInProgress: '0',
    economicImpactAllTime: '0',
    economicImpactMonth: '0',
    economicImpactWeek: '0',
    economicImpactYTD: '0',
    genderDistribution: '0',
    minutesAllTime: '0',
    minutesScheduledThisNextYear: '0',
    minutesThisMonth: '0',
    minutesThisWeek: '0',
    minutesThisYear: '0',
    minutesUnconfirmed: '0',
    name: 'name',
    participatingEducators: '0',
    totalApprovedVolunteers: '0',
    totalEducators: '0',
    totalPendingVolunteers: '0',
    totalVolunteersMonth: '0',
    totalVolunteersWeek: '0',
    totalVolunteersYear: '0',
  };
};

export const mapToSchoolMembersData = (
  schools: NonNullable<DistrictAdminSchoolsQuery['schoolDistrict']>['schools']
): NonNullable<React.ComponentProps<typeof SchoolMembers>['data']> =>
  schools.map(({ id, memberCount: membersCount, requestCount: requestsCount, name }) => ({
    id,
    membersCount,
    name,
    requestsCount,
  }));

export const isDistrict = (value: District | School): value is District =>
  (value as District).schoolDistrictId !== undefined;

export const getAdminType = (value?: District | School): React.ComponentProps<typeof AdminTemplate>['type'] => {
  if (!value) {
    return 'unknown';
  }

  if (isDistrict(value)) {
    return 'district';
  }

  return 'school';
};
