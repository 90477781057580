import { Box } from '@mui/material';
import { useCallback, useState } from 'react';

import { useMyBackgroundChecksQuery } from '../../services/graphql/generated/react-query';
import {
  useBackgroundCheckCreateSelfPay,
  useBackgroundCheckGetProducts,
} from '../../services/openapi/generated/CrowdCoursingComponents';
import { getParsedSelected } from '../../ui/atoms/Form';
import { UserPaysButton, UserPaysButtonProps } from '../../ui/organisms/Buttons/UserPaysButton/UserPaysButton';
import { BackgroundChecks } from '../../ui/organisms/DataGrid';
import { FeatureGuard } from '../FeatureGuard';

type PayButtonProps = {
  districts: Array<{
    schoolDistrictId: string;
    bubbleId: string;
    name: string;
  }>;
};

const PayButton = ({ districts }: PayButtonProps) => {
  const [selectedId, setSelectedId] = useState<string | undefined>(
    // auto-select the district if there's only one
    districts.length === 1 ? districts[0].schoolDistrictId : undefined
  );
  const { data } = useBackgroundCheckGetProducts(
    {
      queryParams: { SchoolDistrictId: selectedId },
    },
    {
      enabled: selectedId != undefined,
    }
  );
  const m = useBackgroundCheckCreateSelfPay();
  const onSubmit = useCallback<UserPaysButtonProps['onSubmit']>(async (values) => {
    if (
      !values.districtSelect ||
      !values.email ||
      !values.firstName ||
      !values.lastName ||
      !values.backgroundCheckProductSelect
    ) {
      return; // ignore empty forms
    }
    // tell the server about it
    const res = await m.mutateAsync({
      body: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        productId: String(getParsedSelected(values.backgroundCheckProductSelect).id),
        schoolDistrictId: String(getParsedSelected(values.districtSelect).id),
      },
    });
    // redirect to payment
    window.location.assign(res.paymentUrl);
  }, []);

  const districtItems = districts.map((x) => ({ id: x.schoolDistrictId, value: x.name }));
  const productItems = data?.map((x) => ({ id: x.id, value: `${x.name} - $${x.cost.toFixed(2)}` })) ?? [];

  return (
    <UserPaysButton
      districts={districtItems}
      onDistrictChange={setSelectedId}
      onSubmit={onSubmit}
      products={productItems}
    />
  );
};

export const BackgroundCheckPanel = () => {
  const { isLoading, data } = useMyBackgroundChecksQuery(undefined, {
    select(data) {
      return {
        backgroundChecks: data.me.backgroundChecks.map((x) => ({ ...x, url: x.url ?? undefined })), // narrow the url type
        districts: data.activeSchoolDistricts,
        userId: data.me.id,
      };
    },
  });

  if (isLoading || !data) {
    return undefined;
  }

  return (
    <>
      <Box paddingBottom="1em">
        <BackgroundChecks data={data.backgroundChecks} />
      </Box>
      <FeatureGuard feature="backgroundChecksPaidByUser">
        <PayButton districts={data.districts} />
      </FeatureGuard>
    </>
  );
};
