import { Print } from '@mui/icons-material';
import { Box, Button, Theme, useMediaQuery } from '@mui/material';
import arrayMutators from 'final-form-arrays';
import { useCallback, useState } from 'react';

import { usePrint } from '~hooks/usePrint';

import { Dialog } from '../../../cc-ui';
import { TypedForm, type TypedFormProps, TypedFormSpy } from './Forms/utils';
import { ResumePreview } from './ResumePreview';
import { ResumeWizard } from './ResumeWizard';
import { ResumeWizardProvider } from './ResumeWizardProvider';

export type ResumeBuilderTemplateBaseProps = {
  initialValues?: TypedFormProps['initialValues'];
  onSubmit: TypedFormProps['onSubmit'];
  status: Utils.QueryStatus;
};
export type ResumeBuilderTemplateSlotProps = Pick<TypedFormProps, 'slotProps'>;
export type ResumeBuilderTemplateProps = ResumeBuilderTemplateBaseProps & ResumeBuilderTemplateSlotProps;

function Container({ children, status, ...props }: ResumeBuilderTemplateProps & React.PropsWithChildren) {
  return (
    <TypedForm
      mutators={{ ...arrayMutators }}
      {...props}
      slotProps={{
        renderContainer: {
          sx(theme) {
            return {
              display: 'grid',
              [theme.breakpoints.down('sm')]: {
                gridTemplateColumns: 'minmax(0, 1fr)',
                gridTemplateRows: 'auto 0px',
              },
              [theme.breakpoints.up('md')]: {
                gap: '2rem',
                gridAutoColumns: 'minmax(384px, 1fr) auto',
                gridAutoFlow: 'column',
              },
            };
          },
        },
      }}
    >
      <ResumeWizardProvider>
        <ResumeWizard slotProps={{ stepper: { sx: { height: 'fit-content' } } }} status={status} />
      </ResumeWizardProvider>
      {children}
    </TypedForm>
  );
}

function Preview({ status }: Pick<ResumeBuilderTemplateProps, 'status'>) {
  return (
    <TypedFormSpy>
      {function ({ values }) {
        const { onPrint, portal } = usePrint(
          <ResumePreview slotProps={{ container: { sx: { border: 'unset' } } }} status={status} values={values} />
        );
        return (
          <Box sx={{ display: 'grid', gap: '0.5rem', gridAutoRows: 'auto 1fr' }}>
            <Box>
              <Button
                disableRipple
                onClick={onPrint}
                size="large"
                startIcon={<Print fontSize="large" />}
                variant="text"
              >
                Print
              </Button>
              {portal}
            </Box>
            <ResumePreview
              slotProps={{ container: { sx: { height: 'fit-content' } } }}
              status={status}
              values={values}
            />
          </Box>
        );
      }}
    </TypedFormSpy>
  );
}

export function ResumeBuilderTemplate(props: ResumeBuilderTemplateProps) {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  const onOpen = useCallback(() => setOpen(true), []);
  const onClose = useCallback(() => setOpen(false), []);

  if (isMobile) {
    return (
      <Box sx={{ display: 'grid', gap: '2rem' }}>
        <Button onClick={onOpen}>Preview Resume</Button>
        <Container
          {...props}
          slotProps={{
            renderContainer: {
              display: 'grid',
              gap: '2rem',
              gridAutoColumns: 'minmax(384px, 1fr) auto',
              gridAutoFlow: 'column',
            },
          }}
        >
          <Dialog
            dialogType="common"
            onDialogClose={onClose}
            open={open}
            shouldShowCloseIcon
            submitProps={{ isHidden: true }}
            title="Resume Preview"
          >
            <Box sx={{ paddingTop: '32px' }}>
              <Preview status={props.status} />
            </Box>
          </Dialog>
        </Container>
      </Box>
    );
  }

  return (
    <Container
      {...props}
      slotProps={{
        renderContainer: {
          display: 'grid',
          gap: '2rem',
          gridAutoColumns: 'minmax(384px, 1fr) auto',
          gridAutoFlow: 'column',
        },
      }}
    >
      <Preview status={props.status} />
    </Container>
  );
}
