import { Delete as DeleteIcon, Edit as EditIcon, QrCode as QrCodeIcon, Share as ShareIcon } from '@mui/icons-material';
import { Avatar, Grid, Tooltip, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import { Route } from '~services/router/Route';

import { ResourceList } from '../../../cc-ui';
import AddMemberButton from '../../../cc-ui/components/MemberManagement/AddMemberButton';
import { useSelector } from '../../../hooks';
import { useDispatch } from '../../../hooks/useTypedRedux';
import { RoomService } from '../../../services/ApiService';
import {
  useDeleteRoomResourceMutation,
  useRoomSummaryQuery,
  useSaveRoomResourceMutation,
} from '../../../services/graphql/generated/react-query';
import { pageDialogOpen } from '../../../state/app';
import { successToast } from '../../../state/notifications';
import { ManageResourcesChip } from '../../../ui/organisms/Resources/Manage';
import { makeBubbleAvatarUrl, makeRoomAvatarUrl, makeRoomUrl } from '../../../utilities/utils';
import { Chat } from '../../Chat/Chat';
import SessionAccordions from '../../Events/Events';
import { FeatureGuard } from '../../FeatureGuard';
import { ManageList } from '../../Shared/ManageList';
import PodActionButton from '../PodActionButton';
import PodDescription from '../PodDescription';
import { ManageSeriesChip } from '../Series';
import { PodPaymentAction } from './../Payment';
import { RsvpTable } from './Rsvps/RsvpTable';
import { Sessions } from './Sessions';

const tEditRoomTooltip = 'Takes you to the room update page';
const tQRTooltip = 'View the check-in QR code';
const tCopyTooltip = 'Copies a link to this room';
const tCopySuccess = 'Room link copied to clipboard!';
const tDeleteRoomTooltip = 'Opens the delete room confirmation modal.';

export const ViewPod = () => {
  const { roomId } = useParams();
  const dispatch = useDispatch();
  const {
    error,
    isLoading,
    data: room,
    refetch,
  } = useRoomSummaryQuery({ id: roomId! }, { select: (data) => data.room });
  const { mutate: saveResource } = useSaveRoomResourceMutation({ onSuccess: () => refetch() });
  const { mutate: deleteResource } = useDeleteRoomResourceMutation({ onSuccess: () => refetch() });
  const userId = useSelector((state) => state.user?.id);
  if (isLoading || !room) {
    return null;
  }

  const {
    userIsFacilitator: isFacilitator,
    userIsOwner: isOwner,
    userIsMember: isMember,
    userIsPending: isPending,
    userIsAdmin: isAdmin,
  } = room;
  const isFacilitatorOrMember = isFacilitator || isMember;
  const canView = isFacilitatorOrMember || room.isSearchable || isAdmin;
  const isRoomLeader = room.userIsOwner;

  const taskListProps = (arr: unknown[]) => ({
    empty: !isLoading && !error && arr.length === 0,
    error: !!(!isLoading && error),
    loading: isLoading,
    success: !isLoading && !error && arr.length > 0,
  });

  const handleClickDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    dispatch(
      pageDialogOpen({
        contentProps: { podName: room.name, roomId: room.id },
        contentType: 'delete-pod',
      })
    );
  };

  const accessibilitySegment = room.isPublic ? 'Open' : 'Private';
  const searchabilitySegment = room.isSearchable ? 'Searchable' : 'Not Searchable';
  const ownerTitle = accessibilitySegment + ' | ' + searchabilitySegment;
  const addressTitle = room.zipCode;
  const sharableRoomLink = makeRoomUrl(room.id);
  const avatarUrl = makeRoomAvatarUrl(room.id);
  const hasResources = room.resources?.length > 0;
  const bubbleAvatarUrl = makeBubbleAvatarUrl(room.bubbleId);

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid container direction="row" item key="TitleRow" xs={12}>
          <Grid item xs={12}>
            <Grid
              alignContent="center"
              container
              direction="column"
              item
              sx={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}
              xs={12}
            >
              <Grid alignContent="center" container direction="row" item xs={12}>
                <Grid item xs={11}>
                  <Grid alignContent="center" container direction="row" item xs={12}>
                    <Grid item>
                      <Avatar src={avatarUrl} sx={{ height: '6em', width: '6em' }} />
                    </Grid>
                    <Grid item marginLeft="0.5em">
                      <Grid container direction="row" item xs={12}>
                        <Grid item>
                          <Typography data-testid="room-name" variant="h4">
                            {room.name + ' '}
                            {!isFacilitator && (
                              <Tooltip placement="top-start" title={tCopyTooltip}>
                                <ShareIcon
                                  onClick={() => {
                                    navigator.clipboard.writeText(sharableRoomLink);
                                    dispatch(successToast({ message: tCopySuccess }));
                                  }}
                                  style={{
                                    cursor: 'pointer',
                                    filter:
                                      'invert(88%) sepia(37%) saturate(4038%) hue-rotate(162deg) brightness(92%) contrast(83%)',
                                    marginLeft: '0.5em',
                                  }}
                                />
                              </Tooltip>
                            )}
                            {isFacilitator && (
                              <Grid container direction="row" xs={12}>
                                <Grid item>
                                  <Tooltip placement="top-start" title={tCopyTooltip}>
                                    <ShareIcon
                                      onClick={() => {
                                        navigator.clipboard.writeText(sharableRoomLink);
                                        dispatch(successToast({ message: tCopySuccess }));
                                      }}
                                      style={{
                                        cursor: 'pointer',
                                        filter:
                                          'invert(88%) sepia(37%) saturate(4038%) hue-rotate(162deg) brightness(92%) contrast(83%)',
                                      }}
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid item marginLeft="0.5em">
                                  <Tooltip placement="top-start" title={tQRTooltip}>
                                    <Link
                                      style={{ color: 'unset', textDecoration: 'none' }}
                                      target="_blank"
                                      to={Route.podQr({ roomId: room.id })}
                                    >
                                      <QrCodeIcon color="primary" />
                                    </Link>
                                  </Tooltip>
                                </Grid>
                                <Grid item marginLeft="0.5em">
                                  <Tooltip placement="top-start" title={tEditRoomTooltip}>
                                    <Link
                                      style={{ color: 'unset', textDecoration: 'none' }}
                                      to={Route.podUpdate({ roomId: room.id })}
                                    >
                                      <EditIcon color="info" />
                                    </Link>
                                  </Tooltip>
                                </Grid>
                                <Grid item marginLeft="0.5em">
                                  <Tooltip placement="top-start" title={tDeleteRoomTooltip}>
                                    <DeleteIcon color="error" onClick={handleClickDelete} />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">{ownerTitle}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6">{addressTitle}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container direction="column" xs={1}>
                  <Grid container direction="row" justifyContent="flex-end" justifyItems="flex-end">
                    <Grid item>
                      <Avatar src={bubbleAvatarUrl} sx={{ height: '6rem', width: '6rem' }} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {canView && (
              <Grid item sx={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
                <ManageList
                  isFacilitator={isFacilitator}
                  items={room.roomMilestones}
                  refetch={refetch}
                  variant={{
                    async onAction(item) {
                      await RoomService.milestones1({ milestoneId: item.id, roomId: room.id });
                    },
                    async onAdd({ description, dueDate, name }) {
                      await RoomService.milestones({
                        body: { description, dueDate, name, roomId: room.id },
                        roomId: room.id,
                      });
                    },
                    type: 'milestones',
                  }}
                  {...taskListProps(room.roomMilestones)}
                />
                <ManageList
                  isFacilitator={isFacilitator}
                  items={room.roomTasks}
                  refetch={refetch}
                  variant={{
                    async onAction(item) {
                      await RoomService.tasks1({ roomId: room.id, taskId: item.id });
                    },
                    async onAdd({ description, dueDate, name }) {
                      await RoomService.tasks({
                        body: { description, dueDate, name, roomId: room.id, userId },
                        roomId: room.id,
                      });
                    },
                    type: 'tasks',
                  }}
                  {...taskListProps(room.roomTasks)}
                />
                {isFacilitator && <ManageSeriesChip refetch={refetch} roomId={room.id} series={room.series} />}
                {isFacilitator && (
                  <ManageResourcesChip
                    onDeleteResource={(item) => deleteResource({ request: { id: item.id, roomId } })}
                    onSaveResource={(newItem, oldItem) =>
                      saveResource({ request: { id: oldItem?.id, roomId, ...newItem } })
                    }
                    resources={room.resources}
                  />
                )}
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" marginTop="1em" xs={12}>
            <Grid hidden={!(isFacilitator || isRoomLeader)} item marginRight="1em">
              <AddMemberButton roomId={room.id} />
            </Grid>
            <Grid item>
              <PodActionButton
                isFacilitator={isFacilitator}
                isMember={isMember}
                isPending={isPending}
                isRoomLeader={isRoomLeader}
                onJoinRequested={refetch}
                onLeave={refetch}
                podName={room.name}
                roomId={room.id}
              />
            </Grid>
            <Grid hidden={!isFacilitatorOrMember} item marginLeft="1em">
              <FeatureGuard feature="payments">
                <PodPaymentAction isOwner={isOwner} ownerId={room.owner.id} />
              </FeatureGuard>
            </Grid>
          </Grid>
        </Grid>
        {isFacilitator && (
          <Grid item key="RsvpRow" marginTop="2em" xs={12}>
            <RsvpTable roomId={room.id} />
          </Grid>
        )}
        <Grid item key="DescriptionRow" marginTop="2em" xs={12}>
          <PodDescription pod={room} />
        </Grid>

        <Grid item key="SessionRow" xs={12}>
          <Grid>
            <Sessions isFacilitator={isFacilitator} isMember={isMember} roomId={room.id} sessions={room.sessions} />
          </Grid>

          {canView && (
            <Grid marginTop="2em">
              <SessionAccordions />
            </Grid>
          )}
        </Grid>
      </Grid>

      {isFacilitatorOrMember && hasResources && (
        <Grid data-testid="resources-row" direction="row" item key="ResourcesRow" marginTop="2em" xs={12}>
          <Typography variant="h5">Resource Links</Typography>
          <ResourceList items={room.resources} />
        </Grid>
      )}

      {isFacilitatorOrMember && (
        <Grid item>
          <Grid item key="Chat" marginTop="2em" xs={12}>
            <Chat roomId={room.id} />
          </Grid>
        </Grid>
      )}
    </>
  );
};
