import { type UserHistoryDto } from '@crowdcoursing/api/schemas';
import { Box, type BoxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

import { Route } from '~services/router/Route';

import { Avatar } from '../../../cc-ui/components';
import { makeRoomAvatarUrl } from '../../../utilities/utils';

const tHost = 'Host';
const tRooms = 'My Rooms';
const tNoRooms = 'No rooms';

function Container(props: BoxProps) {
  return (
    <Box {...props}>
      <Box>
        <Typography gutterBottom variant="h5">
          {tRooms}
        </Typography>
      </Box>
      {props.children}
    </Box>
  );
}

export type UserHistoryRoomsBaseProps = { rooms: NonNullable<UserHistoryDto['rooms']> };
export type UserHistoryRoomsSlotProps = { slotProps?: { container?: BoxProps } };
export type UserHistoryRoomsProps = UserHistoryRoomsBaseProps & UserHistoryRoomsSlotProps;

export const UserHistoryRooms = ({ rooms, slotProps }: UserHistoryRoomsProps) => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const avatarIconSize = isMobile ? '4em' : '5em';

  if (rooms.length === 0) {
    return (
      <Container {...slotProps?.container}>
        <Typography sx={{ fontStyle: 'italic' }}>{tNoRooms}</Typography>
      </Container>
    );
  }

  return (
    <Container {...slotProps?.container}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxHeight: '500px',
          overflowY: 'auto',
          padding: 1,
        }}
      >
        {rooms.map((room) => (
          <>
            <Link
              key={room.id}
              style={{ color: 'inherit', textDecoration: 'none' }}
              to={Route.podView({ roomId: room.id ?? 'NO_ROOM_ID' })}
            >
              <Box
                padding={1}
                sx={{
                  backgroundColor: (theme) => theme.palette.background.paper,
                  borderRadius: 2,
                  boxShadow: 3,
                  display: 'grid',
                  gap: 2,
                  gridAutoColumns: 'auto 1fr',
                  gridAutoFlow: 'column',
                  marginBottom: '0.25em',
                  marginTop: '0.25em',
                  p: 2,
                }}
              >
                <Avatar src={makeRoomAvatarUrl(room.id ?? '')} sx={{ height: avatarIconSize, width: avatarIconSize }} />
                <Box>
                  <Typography sx={{ fontWeight: 'medium' }} variant="h6">
                    {room.name}
                  </Typography>
                  <Typography color="text.secondary" variant="body2">
                    {tHost}: {room.ownerFirstName} {room.ownerLastName}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      display: '-webkit-box',
                      mt: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    variant="body2"
                  >
                    {room.description}
                  </Typography>
                </Box>
              </Box>
            </Link>
          </>
        ))}
      </Box>
    </Container>
  );
};
