import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Route } from '~services/router/Route';

import { useSelector } from '../../hooks';

const PaymentResult = ({ label }: { label: string }) => {
  const id = useSelector((state) => state.app.paymentRoomId!);
  return (
    <>
      <Typography variant="h4">{label}</Typography>
      <br />
      {id ? (
        <Link to={Route.podView({ roomId: id })}>
          <Button>Return to the room</Button>
        </Link>
      ) : (
        <Link to={Route.home()}>
          <Button>Return to home</Button>
        </Link>
      )}
    </>
  );
};

/** displayed when a payment is done processing */
export const Success = () => <PaymentResult label="Payment processed" />;

/** displayed when a payment cancelled */
export const Cancel = () => <PaymentResult label="Payment cancelled" />;
