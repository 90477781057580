import { Link, LinkProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export type RouteLinkProps = LinkProps<typeof RouterLink>;

export const RouteLink = ({ children, ...props }: RouteLinkProps) => {
  return (
    <Link component={RouterLink} {...props}>
      {children}
    </Link>
  );
};
