import { createAsyncThunk } from '@reduxjs/toolkit';
import { Location } from 'react-router-dom';

import { Dispatch, State } from '../hooks/useTypedRedux';
import { LoginService, ProfileDto } from '../services/ApiService';
import { Route } from '../services/router/Route';
import { browserRouter } from '../services/router/router';
import { actions as authActions } from '../state/auth';
import { actions as userActions } from '../state/userSlice';

export type LoginRedirect = {
  from: Location;
};

type LoginProps = {
  email?: string;
  phone?: string;
  redirectState?: LoginRedirect;
};

const login =
  ({ email, phone, redirectState }: LoginProps) =>
  async (dispatch: any) => {
    dispatch(authActions.loginRequested());

    try {
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const result = await LoginService.login({
        body: { email, phoneNumber: phone, timezone },
      });

      if (result === '') {
        // @ts-expect-error - email or phone is required and validated by form and backend
        dispatch(authActions.loginSuccess(email || phone));
        browserRouter.navigate(Route.verify(), { state: redirectState });
      }
    } catch (err) {
      dispatch(authActions.loginFailed());
    }
  };

type VerifyOptions = {
  authCode: number;
  redirect?: LoginRedirect;
};

/**
 * runs the auth code verification process:
 *
 *  1. check the code
 *  1. fetch more user-info from the backend
 *  1. redirect to the most appropriate route
 */
export const verify = createAsyncThunk<void, VerifyOptions, { state: State; dispatch: Dispatch }>(
  'auth/verify',
  async ({ authCode, redirect }, { getState, dispatch }) => {
    const identifier = getState().auth.identifier;
    const token = await LoginService.verify({
      body: { authCode, identifier },
    });

    await loginWithToken(token, dispatch);

    if (redirect) {
      browserRouter.navigate(redirect.from);
    }
    // have they setup a zip code? That probably means they finished
    // profile creation
    else if (getState().user?.zipCode?.length || 0) {
      browserRouter.navigate(Route.home());
    } else {
      browserRouter.navigate(Route.profileCreate());
    }
  }
);

/**
 * login via a token, loading any secondary data
 *
 * This method is used by e2e tests, should contain any additional fetches
 * to load state
 */
export const loginWithToken = async (token: string, dispatch: Dispatch): Promise<void> => {
  dispatch(authActions.verifySuccess(token));
  // use the token to get more data
  const profile = await LoginService.whoami();
  dispatch(successWhoami(profile));
};

export const successWhoami = (user: ProfileDto) => (dispatch: Dispatch) => {
  dispatch(
    userActions.whoamiSuccess({
      user,
    })
  );
};

export const resend = createAsyncThunk<void, void, { state: State; dispatch: Dispatch }>(
  'auth/resend',
  async (_, { getState, dispatch }) => {
    const identifier = getState().auth.identifier;
    try {
      await LoginService.resend({
        body: { identifier },
      });
    } catch (err) {
      dispatch(authActions.loginFailed());
    }
  }
);

export const LoginActions = { login, logout: userActions.logout };
