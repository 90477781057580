import { Print } from '@mui/icons-material';
import {
  IconButton,
  IconButtonProps,
  SvgIconProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { GridValidRowModel, useGridApiContext } from '@mui/x-data-grid-pro';
import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

/** @param component Component is wrapped in `MaterialProvider` */
function onPrint(callback: () => void) {
  // we would not be here if `root` did not exist
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = document.getElementById('root')!;

  const portalRoot = document.createElement('div');
  portalRoot.setAttribute('id', 'print-root');

  function onBeforePrint() {
    if (!root.classList.contains('print-widget-no-print')) {
      root.classList.add('print-widget-no-print');
      root.setAttribute('inert', '');
    }
  }

  function onAfterPrint() {
    portalRoot.remove();

    if (root.classList.contains('print-widget-no-print')) {
      root.classList.remove('print-widget-no-print');
      root.removeAttribute('inert');
    }

    callback();
    window.removeEventListener('beforeprint', onBeforePrint);
    window.removeEventListener('afterprint', onAfterPrint);
  }

  window.addEventListener('beforeprint', onBeforePrint);
  window.addEventListener('afterprint', onAfterPrint);

  const interval = setInterval(function tryPrint() {
    if (document.getElementById('print-widget-print')) {
      window.print();
      clearInterval(interval);
    }
  }, 250);
}

type PrintWidgetBaseProps = Utils.WithTestIds;
type PrintWidgetSlotProps = { slotProps?: { button?: IconButtonProps; icon?: SvgIconProps } };

export type PrintWidgetProps = PrintWidgetBaseProps & PrintWidgetSlotProps;

type PrintTableProps = {
  headers: React.ReactNode[];
  rows: React.ReactNode[][];
};

function PrintTable({ headers, rows }: PrintTableProps) {
  return (
    <TableContainer id="print-widget-print">
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <TableCell key={cellIndex}>{cell}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
// todo: use `usePrint` to replace some of this logic
/** Must be used in the context of a `DataGrid`. */
export const PrintWidget = ({ slotProps }: PrintWidgetProps) => {
  const apiRef = useGridApiContext();
  const [portal, setPortal] = useState<React.ReactPortal>();

  const onClick = useCallback(
    function onClick() {
      const allRows = apiRef.current.getRowModels();
      const visibleRows: GridValidRowModel[] = [];
      Object.entries(apiRef.current.state.visibleRowsLookup).forEach(([rowId, isVisible]) => {
        const row = allRows.get(rowId);
        if (isVisible && row) {
          visibleRows.push(row);
        }
      });

      const visibleColumns = apiRef.current.getVisibleColumns();

      setPortal(() =>
        createPortal(
          <PrintTable
            headers={visibleColumns.map(({ field, headerName }) => headerName ?? field)}
            rows={visibleRows.map((row) => {
              return visibleColumns.map(({ field, renderCell }) => {
                if (renderCell) {
                  // @ts-expect-error We only need the row params here
                  return renderCell({ row });
                }
                return row[field];
              });
            })}
          />,
          document.body
        )
      );
      onPrint(() => setPortal(undefined));
    },
    [apiRef]
  );

  return (
    <>
      <IconButton color="primary" disableRipple onClick={onClick} {...slotProps?.button}>
        <Print {...slotProps?.icon} />
      </IconButton>
      {portal}
    </>
  );
};
