import { GridFilterOperator, useGridApiRef } from '@mui/x-data-grid-pro';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo } from 'react';

import { VolunteeringRecordDto } from '~services/openapi/generated/CrowdCoursingSchemas';
import { Route } from '~services/router/Route';
import { DateRangeValue, FIELD_NAME_DATE_RANGE } from '~ui/atoms/Form';
import { RouteLink } from '~ui/atoms/RouteLink';

import { formatEventTime, formatEventTimes } from '../../../../utilities/utils';
import { DataGrid, DataGridProps } from '../../../molecules/DataGrid/DataGrid';
import { VolunteersHeaderWidgets } from './VolunteersHeaderWidgets';

const tStatus = 'Status';
const tEvent = 'Event';
const tEventTime = 'Event Time';
const tLeaderName = 'Leader Name';
const tTotalMinutes = 'Total Time';
const tMinutes = 'minutes';

export type DataGridVolunteer = Pick<
  VolunteeringRecordDto,
  | 'eventName'
  | 'eventId'
  | 'startTime'
  | 'endTime'
  | 'leaderDisplayName'
  | 'totalMinutes'
  | 'totalMinutesConfirmed'
  | 'volunteerUserId'
>;

export type RowData = Utils.MaybeDeep<{
  confirmed: boolean;
  eventTime: {
    startTime: Dayjs | null;
    endTime: Dayjs | null;
  };
  leader: {
    displayName: string;
  };
  event: {
    id: string;
    name: string;
  };
  totalMinutes: number;
}> & {
  rowId: string;
  userId: string;
};

const eventTimeGetter = (row: RowData) => {
  const { eventTime } = row;
  if (!eventTime) {
    return '';
  }

  const { startTime, endTime } = eventTime;
  if (!startTime || !endTime) {
    return '';
  }

  if (startTime && !endTime) {
    return formatEventTime(startTime.toDate());
  }

  return formatEventTimes(startTime.toDate(), endTime.toDate());
};

const columns: DataGridProps<RowData>['columns'] = [
  {
    field: 'confirmed',
    filterable: true,
    headerName: tStatus,
    renderCell: (params) => {
      return params.row.confirmed ? 'Confirmed' : 'Pending';
    },
    valueGetter: (params) => (params.row.confirmed ? 'Confirmed' : 'Pending'),
  },
  {
    field: 'totalMinutes',
    headerName: tTotalMinutes,
    minWidth: 75,
    renderCell: (params) => {
      return `${params.row.totalMinutes} ${tMinutes}`;
    },
    valueGetter: (params) => {
      return `${params.row.totalMinutes} ${tMinutes}`;
    },
  },
  {
    field: 'event',
    filterable: true,
    headerName: tEvent,
    renderCell: (params) => {
      return (
        <RouteLink sx={{ fontWeight: 600 }} to={Route.eventView({ eventId: params.row.event?.id ?? '' })}>
          {params.row.event?.name ?? ''}
        </RouteLink>
      );
    },
    valueGetter: (params) => {
      return params.row.event?.name ?? '';
    },
    width: 300,
  },
  {
    field: 'eventTime',
    filterOperators: [
      {
        getApplyFilterFn: () => null,
        getApplyFilterFnV7: (filterItem) => {
          if (!Array.isArray(filterItem.value) || filterItem.value.length !== 2) {
            return null;
          }

          if (filterItem.value.some((value) => !value)) {
            return null;
          }

          // (value, row, column, apiRef) => null | boolean
          return (_, row) => {
            const { startTime, endTime } = row.eventTime;
            const [filterBegin, filterEnd]: DateRangeValue = filterItem.value;

            if (!filterBegin || !filterEnd) {
              return false;
            }

            return startTime >= filterBegin.startOf('day').toDate() && endTime <= filterEnd.endOf('day').toDate();
          };
        },
        label: 'Between',
        value: 'between',
      } satisfies GridFilterOperator,
    ],
    filterable: true,
    headerName: tEventTime,
    renderCell: ({ row }) => eventTimeGetter(row),
    sortComparator: (a, b) => {
      return dayjs(a.startTime).isBefore(dayjs(b.startTime)) ? -1 : 1;
    },
    valueGetter: ({ row }) => eventTimeGetter(row),
    width: 150,
  },
  {
    field: 'leader',
    headerName: tLeaderName,
    renderCell: (params) => {
      return params.row.leader?.displayName ?? '';
    },
    valueGetter: (params) => params.row.leader?.displayName ?? '',
    width: 100,
  },
];

const generateRow = (data: DataGridVolunteer, userId: string): RowData => {
  return {
    confirmed: !!data.totalMinutesConfirmed,
    event: {
      id: data.eventId,
      name: data.eventName,
    },
    eventTime: {
      endTime: data.endTime ? dayjs(data.endTime) : null,
      startTime: data.startTime ? dayjs(data.startTime) : null,
    },
    leader: {
      displayName: data.leaderDisplayName?.trim() || 'Unknown',
    },
    rowId: crypto.randomUUID(),
    totalMinutes: data.totalMinutes,
    userId,
  };
};

export type VolunteersProps = Utils.WithQueryInfo<DataGridVolunteer[]> & {
  userId: string;
};

export interface FilterFormValues {
  [FIELD_NAME_DATE_RANGE]: DateRangeValue;
}

export const Volunteers = ({ userId, ...props }: VolunteersProps) => {
  const { data, status } = props;
  const apiRef = useGridApiRef();

  const rows = useMemo(() => data?.map((data) => generateRow(data, userId)) ?? [], [data, userId]);

  const setFilter = useCallback(
    (value: FilterFormValues[typeof FIELD_NAME_DATE_RANGE]) => {
      if (apiRef.current) {
        apiRef.current.setFilterModel({
          items: [{ field: 'eventTime', operator: 'between', value }],
        });
      }
    },
    [apiRef]
  );

  const clearFilter = useCallback(() => {
    if (apiRef.current) {
      apiRef.current.setFilterModel({ items: [] });
      apiRef.current.setPage(0);
    }
  }, [apiRef]);

  const datePickerProps = useMemo(() => {
    return {
      disableFuture: true,
      minDate: rows.reduce((min, row) => {
        // Find the earliest event time to set the min date
        if (!row.eventTime?.startTime) {
          return min;
        }

        return dayjs(row.eventTime.startTime).isBefore(min) ? dayjs(row.eventTime.startTime) : min;
      }, dayjs()),
    };
  }, [rows]);

  return (
    <DataGrid<RowData>
      columns={columns}
      headerWidgets={
        <VolunteersHeaderWidgets clearFilter={clearFilter} datePickerProps={datePickerProps} setFilter={setFilter} />
      }
      rows={rows}
      slotProps={{ dataGrid: { apiRef, disableColumnMenu: false, sx: { maxHeight: '400px' } } }}
      status={status}
    />
  );
};
