import { FilterAlt, FilterAltOff } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { FormSpy } from 'react-final-form';

import { DateRange } from '~ui/atoms/Form';
import { FIELD_NAME_DATE_RANGE, Form } from '~ui/atoms/Form';
import { ModifiedDateRangePickerProps } from '~ui/atoms/Form/DateRange/DateRange';

import { FilterFormValues } from './Volunteers'; // Adjust the import path as necessary

type VolunteerHeadersWidgetsDatePickerProps = Pick<ModifiedDateRangePickerProps, 'disableFuture' | 'minDate'>;

interface VolunteersHeaderWidgetsProps {
  setFilter: (value: FilterFormValues[typeof FIELD_NAME_DATE_RANGE]) => void;
  clearFilter: () => void;
  datePickerProps?: VolunteerHeadersWidgetsDatePickerProps;
}

export const VolunteersHeaderWidgets = ({ setFilter, clearFilter, datePickerProps }: VolunteersHeaderWidgetsProps) => {
  return (
    <Form<FilterFormValues>
      onSubmit={(values) => setFilter(values[FIELD_NAME_DATE_RANGE])}
      slotProps={{
        renderContainer: {
          alignItems: 'center',
          display: 'grid',
          gap: '2rem',
          gridAutoColumns: 'minmax(0, 208px) minmax(0, auto)',
          gridAutoFlow: 'column',
        },
      }}
    >
      <DateRange dateRangePickerProps={{ ...datePickerProps }} />
      <FormSpy<FilterFormValues>
        render={({ form, values }) => {
          const disabled = !(
            values[FIELD_NAME_DATE_RANGE]?.length === 2 && values[FIELD_NAME_DATE_RANGE]?.every((value) => !!value)
          );
          return (
            <Box>
              <IconButton
                aria-label="Filter"
                color="primary"
                disableRipple
                disabled={disabled}
                onClick={() => form.submit()}
              >
                <FilterAlt />
              </IconButton>
              <IconButton
                aria-label="Clear filter"
                color="primary"
                disableRipple
                onClick={() => {
                  form.initialize({ [FIELD_NAME_DATE_RANGE]: undefined });
                  clearFilter();
                }}
              >
                <FilterAltOff />
              </IconButton>
            </Box>
          );
        }}
      />
    </Form>
  );
};
