import { Box, Typography } from '@mui/material';
import { type PartialDeep } from 'type-fest';

import { TextArrayField } from '~ui/atoms/Form';

import { ResumeWizardNavigation } from '../ResumeWizardNavigation';
import { TypedFormSpy } from './utils';

export type SkillsFieldValues = {
  skills: string[];
};

export function getFieldName(name: `${keyof SkillsFieldValues}`) {
  return name;
}

export type SkillsBaseProps = { initialValues?: PartialDeep<SkillsFieldValues['skills']> };
export type SkillsSlotProps = { slotProps?: object };
export type SkillsProps = SkillsBaseProps & SkillsSlotProps;

export function Skills({ initialValues }: SkillsProps) {
  return (
    <Box data-testid="Skills Form" display="grid" gap="1.5rem">
      <TextArrayField
        initialValue={initialValues}
        name={getFieldName('skills')}
        textFieldProps={{
          'data-testid': getFieldName('skills'),
          helperText: 'Each line is a separate skill',
          label: 'Skills',
          variant: 'filled',
        }}
      />
      <TypedFormSpy>
        {function ({ hasValidationErrors, form: { submit: onClick }, submitError }) {
          const disabled = hasValidationErrors;
          return (
            <>
              <Typography color="error.main" variant="overline">
                {submitError}
              </Typography>
              <ResumeWizardNavigation slotProps={{ next: { disabled, onClick } }} />
            </>
          );
        }}
      </TypedFormSpy>
    </Box>
  );
}
