import { Box, type BoxProps, Tab as MuiTab, type TabProps as MuiTabProps, Tabs as MuiTabs } from '@mui/material';

import { useTabs, type UseTabsOptions } from './useTabs';

type Tab = { label: React.ReactNode; component: React.ReactNode };

export type TabsBaseProps = { options?: UseTabsOptions; tabs: Tab[] };
export type TabsSlotProps = {
  slotProps?: {
    container?: BoxProps;
    contentContainer?: BoxProps;
    tab?: MuiTabProps | ((index: number) => MuiTabProps);
  };
};
export type TabsProps = TabsBaseProps & TabsSlotProps;

export function Tabs({ options, slotProps, tabs }: TabsProps) {
  const { current, onChangeTab } = useTabs(options);

  return (
    <Box {...slotProps?.container}>
      <MuiTabs onChange={onChangeTab} value={current}>
        {tabs.map(function ({ label }, index) {
          return (
            <MuiTab
              data-testid={`Tab${index}`}
              {...(typeof slotProps?.tab === 'function' ? slotProps?.tab?.(index) : slotProps?.tab)}
              aria-controls={`tab-content-${index}`}
              id={`tab-${index}`}
              key={`tab-${index}`}
              label={label}
            />
          );
        })}
      </MuiTabs>
      {tabs.map(function ({ component }, index) {
        if (current !== index) {
          return null;
        }

        return (
          <Box
            aria-labelledby={`tab-${index}`}
            data-testid={`TabContent${index}"`}
            id={`tab-content-${index}`}
            key="label"
            role="tabpanel"
            {...slotProps?.contentContainer}
          >
            {component}
          </Box>
        );
      })}
    </Box>
  );
}
