import { FormSpy, useField, useForm, useFormState } from 'react-final-form';
import { type PartialDeep } from 'type-fest';

import { Form } from '~ui/atoms/Form';

import { type CertificationsFieldValues } from './Certifications';
import { type EducationFieldValues } from './Education';
import { type PersonalInformationFieldValues } from './PersonalInformation';
import { type ProfessionalExperiencesFieldValues } from './ProfessionalExperiences';
import { type ProjectsFieldValues } from './Projects';
import { type SkillsFieldValues } from './Skills';

export type ResumeBuilderFormFieldValues = CertificationsFieldValues &
  EducationFieldValues &
  PersonalInformationFieldValues &
  ProfessionalExperiencesFieldValues &
  ProjectsFieldValues &
  SkillsFieldValues;
export type TypedFormProps = React.ComponentProps<typeof TypedForm>;

export const TypedForm = Form<PartialDeep<ResumeBuilderFormFieldValues>>;
export const TypedFormSpy = FormSpy<ResumeBuilderFormFieldValues>;
export const useTypedField = useField<ResumeBuilderFormFieldValues>;
export const useTypedForm = useForm<ResumeBuilderFormFieldValues>;
export const useTypedFormState = useFormState<ResumeBuilderFormFieldValues>;
