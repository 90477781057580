import { useCallback, useState } from 'react';
import { createPortal } from 'react-dom';

function appendStylesheet() {
  if (document.getElementById('print-style')) {
    return;
  }

  const style = document.createElement('style');
  style.setAttribute('id', 'print-style');
  style.setAttribute('type', 'text/css');
  style.innerHTML = `
    #print-container { display: none; pointer-events: none; visibility: hidden; }

    @media print {
      body > :not(#print-container) { display: none; pointer-events: none; visibility: hidden; }
      #print-container { display: unset; pointer-events: initial; visibility: initial; }
    }
  `;
  document.head.appendChild(style);
}

type PrintOptions = {
  onAfterPrint?: () => void;
  onBeforePrint?: () => void;
};

function print(options?: PrintOptions) {
  appendStylesheet();

  function onBeforePrint() {
    options?.onBeforePrint?.();
  }

  function onAfterPrint() {
    options?.onAfterPrint?.();
    window.removeEventListener('beforeprint', onBeforePrint);
    window.removeEventListener('afterprint', onAfterPrint);
  }

  window.addEventListener('beforeprint', onBeforePrint);
  window.addEventListener('afterprint', onAfterPrint);

  const interval = setInterval(function tryPrint() {
    if (document.getElementById('print-container')) {
      window.print();
      clearInterval(interval);
    }
  }, 250);
}

/**
 * Utility hook for displaying a specific component in the print dialog.
 *
 * @example
 * function MyComponent() {
 *  const { onPrint, portal } = usePrint(<Bar />)
 *
 *  return (
 *    <div>
 *      <Foo />
 *      <button onClick={onPrint}>
 *        Print Bar
 *        {portal} // `portal` must be rendered! It does not matter where.
 *      </button>
 *      <Bar />
 *    </div>
 *  )
 * }
 */
export function usePrint(component: React.ReactNode, options?: PrintOptions) {
  const [portal, setPortal] = useState<React.ReactNode>();

  const onPrint = useCallback(
    function () {
      setPortal(function () {
        return <>{createPortal(<div id="print-container">{component}</div>, document.body)}</>;
      });
      print({
        ...options,
        onAfterPrint() {
          setPortal(undefined);
          options?.onAfterPrint?.();
        },
      });
    },
    [options]
  );

  return { onPrint, portal };
}
