import { useUserGetUserHistoryInformation } from '@crowdcoursing/api/components';

import { useFeatures } from '~hooks/useFeature';
import { UserProfileTemplate, UserProfileTemplateProps } from '~ui/templates/UserProfileTemplate/UserProfileTemplate';
import { asyncify } from '~utils/asyncify';

import { useResumeValues } from '../ResumeBuilder/useResumeBuilder';

function useUserProfileTemplateProps(): UserProfileTemplateProps {
  const { showResumes: showResume, showUserHistory, showVolunteerStats } = useFeatures();
  const { status: resumeValuesStatus, values, userId } = useResumeValues({ enabled: showResume });
  const { status: userHistoryStatus, data } = useUserGetUserHistoryInformation(
    { pathParams: { userId } },
    { enabled: showUserHistory }
  );

  return {
    flags: { showResume, showUserHistory },
    resumePreviewProps: { status: resumeValuesStatus, values },
    userHistoryProps: { data: data ?? {}, showVolunteerStats, status: userHistoryStatus },
  };
}

export function UserProfile() {
  const userProfileTemplateProps = useUserProfileTemplateProps();
  return <UserProfileTemplate {...userProfileTemplateProps} />;
}

export const UserProfileAsync = asyncify(UserProfile);
