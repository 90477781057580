import { Box, Grid, Link, Typography } from '@mui/material';
import { useId } from 'react';

import { Avatar } from '..';

const tNoResources = '';
const avatarIconSize = '2em';

/**
 * an external resource we want to link to
 */
export interface Resource {
  id: string;
  name: string;
  url: string;
  description?: string | null;
}

export interface ResourceListProps {
  items: Resource[];
}

const ResourceLink = ({ name, url, description }: Resource) => {
  // TODO: use atoms/ExternalLink
  const faviconUrl = 'https://s2.googleusercontent.com/s2/favicons?domain=' + url + '&sz=256';
  const linkId = useId();
  return (
    <Box
      data-testid="resource-card"
      marginTop={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Link
        aria-labelledby={linkId}
        data-testid={'resource-link-' + name}
        href={url}
        rel="noopener"
        target="_blank"
        underline="none"
        variant="body1"
      >
        <Box
          sx={{
            backgroundColor: (theme) => theme.palette.background.paper,
            borderRadius: 1,
            boxShadow: 1,
            padding: 1,
            wordBreak: 'break-word',
          }}
        >
          <Grid alignItems="center" container direction="row">
            <Grid item xs={2}>
              <Avatar src={faviconUrl} sx={{ height: avatarIconSize, width: avatarIconSize }} />
            </Grid>
            <Grid item xs={10}>
              <Typography
                paddingRight={1}
                sx={{
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  display: '-webkit-box',
                  fontWeight: 'medium',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                variant="body1"
              >
                {name}
              </Typography>
            </Grid>
            <Grid item>
              {description && (
                <Typography
                  color="text.secondary"
                  padding={1}
                  sx={{
                    WebkitBoxOrient: 'vertical',
                    display: '-webkit-box',
                  }}
                  variant="body2"
                >
                  {description}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Link>
    </Box>
  );
};

// TODO: move to molecules
export const ResourceList = ({ items }: ResourceListProps) => {
  if (items.length == 0) {
    // TODO: use atoms/Empty
    return <Typography variant="body1">{tNoResources}</Typography>;
  }

  return (
    <Grid container spacing={2} xs={12}>
      {items.map((i) => (
        <Grid item key={i.id} lg={3} sm={6} xs={12}>
          <ResourceLink {...i} />
        </Grid>
      ))}
    </Grid>
  );
};
