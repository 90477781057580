import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const ONE_SECOND = 1000;
export const ONE_MINUTE = 60 * ONE_SECOND;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_WEEK = 7 * ONE_DAY;
export const ONE_YEAR = 52 * ONE_WEEK;
export const THIRTY_DAYS = 30 * ONE_DAY;
export const SIXTY_DAYS = 60 * ONE_DAY;
export const NINETY_DAYS = 90 * ONE_DAY;

const makeUrl = (pathname: string): string => {
  if (import.meta.env.REACT_APP_BACKEND_URL === '/') {
    // we're in a CORS-less mode, paths to the frontend will work
    return fullyQualifiedUrl(pathname);
  }
  if (import.meta.env.REACT_APP_BACKEND_URL) {
    const url = new URL(import.meta.env.REACT_APP_BACKEND_URL);
    url.pathname = pathname;
    return url.href;
  }
  throw new Error('please define REACT_APP_BACKEND_URL');
};

export type ImageResourceType = 'Profile' | 'room' | 'event' | 'organization' | 'Bubbles';
export const makeImageResourceAvatarUrl = (resourceType: ImageResourceType, resourceId: string): string =>
  makeUrl(`/api/v1/${resourceType}/${resourceId}/picture`);
export const makeProfileAvatarUrl = (userId: string): string => makeImageResourceAvatarUrl('Profile', userId);
export const makeRoomAvatarUrl = (roomId: string): string => makeImageResourceAvatarUrl('room', roomId);
export const makeEventAvatarUrl = (eventId: string): string => makeImageResourceAvatarUrl('event', eventId);
export const makeOrganizationAvatarUrl = (organizationId: string): string =>
  makeImageResourceAvatarUrl('organization', organizationId);
export const makeBubbleAvatarUrl = (bubbleId: string): string => makeImageResourceAvatarUrl('Bubbles', bubbleId);

/** @deprecated use fullyQualifiedUrl and `services/router/Route` */
export const makeRoomUrl = (roomId: string): string => {
  if (window.location.href) {
    const url = new URL(window.location.href);
    url.pathname = `/rooms/${roomId}`;
    return url.href;
  }
  throw new Error('please define REACT_APP_BACKEND_URL');
};

/** builds a full url to the given path, intended to be used with
 * `services/router/Route` */
export const fullyQualifiedUrl = (pathname: string): string => {
  const url = new URL(window.location.href);
  url.pathname = pathname;
  return url.href;
};

/** sleeps for the given amount of time */
export const sleep = (delayMillis: number): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, delayMillis);
  });

/** retry an async operation with delays */
export const withRetries = async (callback: () => Promise<void>, retries = 5, delayMillis = 1000): Promise<boolean> => {
  for (let i = 0; i < retries; i++) {
    try {
      await callback();
      return true;
    } catch (err) {
      console.warn('unexpected error', err);
      await sleep(delayMillis); // wait a little before trying again
    }
  }
  return false;
};

export const getDate = (timestamp: Date | string, format = 'MMM DD') => dayjs(timestamp).format(format);
export const getTime = (timestamp: Date | string, format = 'h:mma') => dayjs(timestamp).format(format);
export const getTimeRange = (beginTime: Date, endTime: Date) =>
  `${getTime(beginTime)} - ${getTime(endTime, 'h:mma z')}`;
export const getTimeRangeWithDate = (beginTime: Date | string, endTime: Date | string) =>
  `${getTime(beginTime, 'ddd, MMM D h:mma')} - ${getTime(endTime, 'h:mma z')}`;
export const getIsInPast = (timestamp: Date | string) => dayjs(timestamp).isBefore(dayjs(), 'day');

/**
 * Generate meeting time by taking the begin time and end time
 * and creating a string like the following; Jan 02 2:15p-3:30p
 *
 * Return '' if either arg is undefined, which hides the Meeting Time label
 */
export const generateMeetingTime = (beginTime?: Date | string, endTime?: Date | string) => {
  if (!beginTime || !endTime) {
    return '';
  }

  return `${getDate(beginTime)} ${getTime(beginTime)}-${getTime(endTime)}`;
};

/** is the given token expired? */
export const isTokenExpired = (token: string): boolean => {
  try {
    // decode JWT, find the expiration date
    const exp = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000);
    return new Date() > exp;
  } catch (e) {
    return true;
  }
};

export const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
  } catch (exception) {
    return false;
  }

  return true;
};

/**
 * Formats the event time in the format of Aug. 12, 2024 - Aug. 13, 2024
 * or Aug. 12, 2024 10:00 AM - 2:00 PM for same day events.
 * If the inputs are invalid, we return empty string.
 * @param startTime
 * @param endTime
 * @returns
 */
export const formatEventTimes = (startTime: Date, endTime: Date) => {
  if (endTime && startTime && endTime < startTime) {
    return '';
  }

  const sameDay = endTime && startTime && startTime?.toDateString() === endTime?.toDateString();
  // If it's on the same day
  if (sameDay) {
    return `${formatEventTime(startTime)} - ${formatNumericEventTime(endTime)}`;
  }

  // If it's on different days
  if (startTime && endTime) {
    return `${formatEventTime(startTime)} - ${formatEventTime(endTime)}`;
  }

  // If there's a start time but not an end time
  if (startTime && !endTime) {
    return formatEventTime(startTime);
  }

  // Default to empty string to avoid NRE and weird object returns
  return '';
};

/**
 * Formats '12/17/24 9:00 AM - 10:30 AM' for same day events.
 * Formats '1/1/24 9:00 AM - 1/2/24 10:00 AM' for events spanning days.
 * If the inputs are invalid, we return empty string.
 * @param time
 * @returns
 */
export const formatEventTime = (time: Date) => {
  if (!time) {
    return '';
  }

  return `${formatDefaultEventDate(time)} ${formatNumericEventTime(time)}`;
};

const formatDefaultEventDate = (time: Date) => {
  return time.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
  });
};

const formatNumericEventTime = (time: Date) => {
  return time.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
  });
};
