import { Box, Typography } from '@mui/material';
import { type PartialDeep } from 'type-fest';

import { Date, DateValue, DragAndDropFieldArray, TextArrayField, TextField } from '~ui/atoms/Form';

import { ResumeWizardNavigation } from '../ResumeWizardNavigation';
import { TypedFormSpy } from './utils';

export const MAX_NUMBER_OF_PROFESSIONAL_EXPERIENCES = 5;
export const DEFAULT_PROFESSIONAL_EXPERIENCE: ProfessionalExperienceFieldValue = {
  achievements: undefined,
  companyName: undefined,
  end: undefined,
  jobTitle: undefined,
  start: undefined,
};

export type ProfessionalExperienceFieldValue = {
  achievements?: string[];
  companyName?: string;
  end?: DateValue;
  jobTitle?: string;
  start?: DateValue;
};

export type ProfessionalExperiencesFieldValues = {
  professionalExperiences: ProfessionalExperienceFieldValue[];
};

export function getFieldName(name: `${keyof ProfessionalExperiencesFieldValues}`) {
  return name;
}

export function getSubfieldName(base: string, name: `${keyof ProfessionalExperienceFieldValue}`) {
  return `${base}.${name}`;
}

export type ProfessionalExperiencesBaseProps = {
  initialValues?: PartialDeep<ProfessionalExperiencesFieldValues['professionalExperiences']>;
};
export type ProfessionalExperiencesSlotProps = { slotProps?: object };
export type ProfessionalExperiencesProps = ProfessionalExperiencesBaseProps & ProfessionalExperiencesSlotProps;

export function ProfessionalExperiences({ initialValues }: ProfessionalExperiencesProps) {
  return (
    <Box data-testid="Professional Experience Form" display="grid" gap="1.5rem">
      <DragAndDropFieldArray<ProfessionalExperienceFieldValue>
        initialValue={initialValues}
        itemTemplate={DEFAULT_PROFESSIONAL_EXPERIENCE}
        max={MAX_NUMBER_OF_PROFESSIONAL_EXPERIENCES}
        name={getFieldName('professionalExperiences')}
        translations={{ add: 'Add Experience', remove: 'Remove Experience' }}
      >
        {function ({ index, name }) {
          return (
            <>
              <TextField
                name={getSubfieldName(name, 'companyName')}
                textFieldProps={{
                  'data-testid': getSubfieldName(name, 'companyName'),
                  label: 'Company Name',
                  variant: 'filled',
                }}
                validate={function (value, _, meta) {
                  if (meta?.initial === undefined && !meta?.modified) {
                    return;
                  }

                  if (!value) {
                    return 'Company Name required';
                  }
                }}
              />
              <TextField
                name={getSubfieldName(name, 'jobTitle')}
                textFieldProps={{
                  'data-testid': getSubfieldName(name, 'jobTitle'),
                  label: 'Job Title',
                  variant: 'filled',
                }}
                validate={function (value, _, meta) {
                  if (meta?.initial === undefined && !meta?.modified) {
                    return;
                  }

                  if (!value) {
                    return 'Job Title required';
                  }
                }}
              />
              <Date
                datePickerProps={{
                  'data-testid': getSubfieldName(name, 'start'),
                  disableFuture: true,
                  slotProps: { textField: { variant: 'filled' } },
                }}
                label="Start Date"
                name={getSubfieldName(name, 'start')}
                validate={function (value, values) {
                  const { professionalExperiences }: ProfessionalExperiencesFieldValues =
                    values as ProfessionalExperiencesFieldValues;

                  const end = professionalExperiences[index]?.end;
                  if (!value && end) {
                    return 'Start Date required';
                  }

                  if (end && value?.isSameOrAfter(end)) {
                    return 'Start Date must be before End Date';
                  }
                }}
              />
              <Date
                datePickerProps={{
                  'data-testid': getSubfieldName(name, 'end'),
                  disableFuture: true,
                  slotProps: { textField: { variant: 'filled' } },
                }}
                label="End Date"
                name={getSubfieldName(name, 'end')}
                validate={function (value, values) {
                  const { professionalExperiences }: ProfessionalExperiencesFieldValues =
                    values as ProfessionalExperiencesFieldValues;

                  const start = professionalExperiences[index]?.start;
                  if (start && value?.isSameOrBefore(start)) {
                    return 'End Date must be after Start Date';
                  }
                }}
              />
              <TextArrayField
                name={getSubfieldName(name, 'achievements')}
                textFieldProps={{
                  'data-testid': getSubfieldName(name, 'achievements'),
                  helperText: 'Each line is a separate achievement',
                  label: 'Professional Achievements',
                  variant: 'filled',
                }}
              />
            </>
          );
        }}
      </DragAndDropFieldArray>
      <TypedFormSpy>
        {function ({ values, hasValidationErrors, form: { submit: onClick }, submitError }) {
          const disabled =
            (values?.professionalExperiences ?? []).some((experience) =>
              Object.values(experience).some((value) => value === undefined)
            ) || hasValidationErrors;
          return (
            <>
              <Typography color="error.main" variant="overline">
                {submitError}
              </Typography>
              <ResumeWizardNavigation slotProps={{ next: { disabled, onClick } }} />
            </>
          );
        }}
      </TypedFormSpy>
    </Box>
  );
}
