import { Grid, Link as MuiLink, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

import { Route } from '~services/router/Route';

import { Rsvp as RsvpTable, RsvpProps as RsvpTableProps } from '../../../../cc-ui/components/Tables/Schedule/Rsvp';
import { useSelector } from '../../../../hooks';
import { useUserGetUserRsvpsSearch } from '../../../../services/openapi/generated/CrowdCoursingComponents';

const tBody = 'Nothing yet! ';
const tGoToSearch = 'Search for events?';
const tRsvpSchedule = 'Upcoming Schedule';

export const Rsvp = () => {
  const userId = useSelector((state) => state.user?.id ?? '');
  const { data, error, isLoading } = useUserGetUserRsvpsSearch({ pathParams: { userId } });

  if (isLoading || !data) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Oops...</p>;
  }

  const rsvps: RsvpTableProps['rsvps'] = data.map(({ schoolName, eventId, eventName, meetingTimeDto }) => ({
    beginMeetingTime: new Date(meetingTimeDto!.beginMeetingTime!),
    endMeetingTime: new Date(meetingTimeDto!.endMeetingTime!),
    eventId: eventId!,
    eventName: eventName!,
    rsvpUserId: userId,
    schoolName: schoolName,
  }));
  const handleClickRow: RsvpTableProps['onClickRow'] = (rsvpUserId, eventId) =>
    window.console.log({ eventId, rsvpUserId });

  if (rsvps.length === 0) {
    return (
      <>
        <Grid item xs={12}>
          <Typography variant="h5">{tRsvpSchedule}</Typography>
        </Grid>
        <Grid item marginTop="1em" xs={12}>
          <Typography data-testid="no-rsvps" variant="body1">
            {tBody}
            <MuiLink component={Link} to={Route.podsSearch()}>
              {tGoToSearch}
            </MuiLink>
          </Typography>
        </Grid>
      </>
    );
  }

  return <RsvpTable onClickRow={handleClickRow} rsvps={rsvps} />;
};
