import { Button, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import { FIELD_NAME_PHONE } from '~ui/molecules/Form/Phone/Phone';

import { LoginActions } from '../../actions/login';
import { useDispatch, useSelector } from '../../hooks/useTypedRedux';
import { Form, FormProps } from '../../ui/atoms/Form';
import { EmailOrPhone, FIELD_NAME_EMAIL, FIELD_NAME_TERMS_OF_SERVICE, TermsOfService } from '../../ui/molecules/Form';
import { FeatureGuard } from '../FeatureGuard';
import Logo from '../Logo/Logo';
import { Card, Header } from './Login.styles';
import { LoginAsTestUserButton } from './LoginAsTestUserButton';
import { LoginVideo } from './LoginVideo';

const tSignIn = 'Sign In';
const tSignInDescription =
  '** If you do not already have an account, one will be created for you upon entering your information.';

interface LoginFormValues {
  [FIELD_NAME_EMAIL]: string | undefined;
  [FIELD_NAME_PHONE]: string | undefined;
  [FIELD_NAME_TERMS_OF_SERVICE]: string[] | undefined;
  isLoading: boolean;
}

type LoginFormContentProps = {
  isLoading: boolean;
};

const LoginFormContent = ({ isLoading }: LoginFormContentProps) => {
  const { hasValidationErrors, values } = useFormState<LoginFormValues>();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <EmailOrPhone textFieldProps={{ autoFocus: true, variant: 'outlined' }} />
      </Grid>
      <Grid container direction="row" item>
        <Grid alignItems="center" container item lg={6} xs={12}>
          <TermsOfService />
        </Grid>
        <Grid
          container
          item
          justifyContent="flex-end"
          lg={6}
          marginTop={(theme) => (theme.breakpoints.down('sm') ? '0rem' : '1rem')}
          sx={{ height: 'auto' }}
          xs={12}
        >
          <Grid item>
            <Button
              data-cy="loginButton"
              disabled={
                isLoading ||
                hasValidationErrors ||
                !values[FIELD_NAME_TERMS_OF_SERVICE] ||
                (!values[FIELD_NAME_EMAIL] && !values[FIELD_NAME_PHONE])
              }
              size="large"
              type="submit"
            >
              {tSignIn} {isLoading && <CircularProgress color="inherit" size={20} style={{ marginLeft: '0.5rem' }} />}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <FeatureGuard feature="loginAsTestUser">
        <Grid item>
          <LoginAsTestUserButton />
        </Grid>
      </FeatureGuard>
    </Grid>
  );
};

export const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  const handleSubmit = useCallback<FormProps<LoginFormValues>['onSubmit']>(
    (values) => {
      if (isLoading) return;

      const { email, phone, termsOfService } = values;

      // Remove non-numeric characters from phone number, the backend expects phone numbers in this format
      // e.g. 1234567890
      const sanitizedPhone = phone ? phone.replace(/\D/g, '') : undefined;
      if (!termsOfService || (!email && !sanitizedPhone)) return;

      dispatch(LoginActions.login({ email, phone, redirectState: location.state }));
    },
    [isLoading]
  );

  return (
    <Grid
      alignContent="center"
      alignItems="center"
      container
      direction="column"
      justifyContent="center"
      justifyItems="center"
    >
      <Grid alignContent="center" container item justifyContent="center" marginBottom="3rem" width="100%" xs={12}>
        <Logo />
      </Grid>
      <Grid container direction="row" item width="100%" xs={12}>
        <Card elevation={2}>
          <CardContent>
            <Grid container direction="row" item xs={12}>
              <Grid marginBottom="2em">
                <Header color="#359dd8" variant="h2">
                  {tSignIn}
                </Header>
              </Grid>
            </Grid>
            <Form<LoginFormValues> onSubmit={handleSubmit}>
              {({ submitting, submitSucceeded }) => <LoginFormContent isLoading={submitting || submitSucceeded} />}
            </Form>
            <Typography variant="body2">{tSignInDescription}</Typography>
          </CardContent>
        </Card>
        <Grid container direction="row" item marginTop="1em" minHeight="500px" width="100%" xs={12}>
          <LoginVideo />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
