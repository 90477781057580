import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const DEFAULT_SEARCH_PARAM_KEY = 'tab';
export const OFFSET = 1;

function initialize(value: string | null, offset: number) {
  if (!value) {
    return 0;
  }

  const maybeNumber = Number(value);
  if (!Number.isNaN(maybeNumber)) {
    return maybeNumber - offset;
  }

  return 0;
}

export type UseTabsOptions = { syncWithSearchParams: true; searchParamKey?: string } | { syncWithSearchParams?: false };

export function useTabs(options?: UseTabsOptions) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [current, setCurrent] = useState(
    options?.syncWithSearchParams
      ? initialize(searchParams.get(options?.searchParamKey ?? DEFAULT_SEARCH_PARAM_KEY), OFFSET)
      : 0
  );

  const onChangeTab = useCallback(function (_event: React.SyntheticEvent, selected: number) {
    setCurrent(selected);
  }, []);

  useEffect(() => {
    if (!options?.syncWithSearchParams) {
      return;
    }

    setSearchParams((previous) => {
      return new URLSearchParams({
        ...Object.fromEntries(previous.entries()),
        [options?.searchParamKey ?? DEFAULT_SEARCH_PARAM_KEY]: String(current + OFFSET),
      });
    });
  }, [current]);

  return { current, onChangeTab };
}
