import { Grid } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-final-form';

import { Email } from '../Email/Email';
import { FIELD_NAME_EMAIL } from '../Email/Email';
import { LoginTabs } from '../LoginTabs/LoginTabs';
import { Phone } from '../Phone/Phone';
import { FIELD_NAME_PHONE } from '../Phone/Phone';
import { ConstructedTextField } from '../types';

export type EmailOrPhoneProps = ConstructedTextField;

type Tab = 'email' | 'phone';

const INITIAL_TAB: Tab = 'email';

export const EmailOrPhone = ({ textFieldProps, ...props }: EmailOrPhoneProps) => {
  const [activeTab, setActiveTab] = useState<Tab>(INITIAL_TAB);
  const form = useForm();

  const handleTabChange = (tab: Tab) => {
    setActiveTab(tab);
    // Reset relevant fields
    form.reset({
      ...form.getState().values,
      [FIELD_NAME_EMAIL]: '',
      [FIELD_NAME_PHONE]: '',
    });
  };

  return (
    <Grid item>
      <LoginTabs activeTab={activeTab} onTabChange={handleTabChange} />
      {activeTab === 'email' && (
        <Email
          name={FIELD_NAME_EMAIL}
          required={true}
          textFieldProps={{
            'data-cy': 'userLoginEmail',
            ...textFieldProps,
          }}
          {...props}
        />
      )}
      {activeTab === 'phone' && (
        <Phone
          name={FIELD_NAME_PHONE}
          required={true}
          textFieldProps={{
            'data-cy': 'userLoginPhone',
            ...textFieldProps,
          }}
          {...props}
        />
      )}
    </Grid>
  );
};
