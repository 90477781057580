import { FeatureGuard } from '../FeatureGuard';

export interface YouTubeEmbedProps extends React.HTMLProps<HTMLIFrameElement> {
  embedId: string;
}

export const YouTubeEmbed = ({ embedId, ...props }: YouTubeEmbedProps) => (
  <FeatureGuard feature="homePageVideos">
    <iframe
      allow="accelerometer; encrypted-media; gyroscope;"
      allowFullScreen
      src={`https://www.youtube.com/embed/${embedId}?rel=0`}
      {...props}
    />
  </FeatureGuard>
);

export default YouTubeEmbed;
