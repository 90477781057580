export function filterNull<T>(value: T | null | undefined): value is T {
  return value !== null;
}

export function filterUndefined<T>(value: T | null | undefined): value is T {
  return value !== undefined;
}

export function filterNullAndUndefined<T>(value: T | null | undefined): value is T {
  return filterNull(value) && filterUndefined(value);
}

export function filterBoolean<T>(value: T | boolean): value is T {
  return value !== true && value !== false;
}
