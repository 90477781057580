import { generatePath, PathParam } from 'react-router-dom';

/**
 * Utility type for better intellsense when using `react-router-dom#useParams`.
 * @example
 * const { organizationId } = useParams<RouteParams<RawRoute.organization>>();
 */
export type RouteParams<Path extends string> = { [Key in PathParam<Path>]: Key };

/** Calls generatePath as a curried function with type enforced parameters */
const generatePathWithParams =
  <Path extends string>(originalPath: Path) =>
  (params: {
    [key in PathParam<Path>]: string | null;
  }) =>
    generatePath(originalPath, params);

/** Calls generatePath as a curried function with no parameters */
const generatePathNoParams =
  <Path extends string>(originalPath: Path) =>
  () =>
    generatePath(originalPath);

/* eslint-disable sort-keys-fix/sort-keys-fix */
/** This should be used sparingly as it provides little type safety when consuming routes */
export const RawRoute = {
  index: '/',

  // authentication
  login: '/login',
  logout: '/logout',
  verify: '/verify',
  home: '/home',

  // admin
  admin: '/admin',
  adminSchoolMembership: '/admin/schools/:id/membership',

  // organizations
  organizations: '/organizations',
  organization: '/organization/:organizationId',

  // profile
  profile: '/profile',
  profileCreate: '/profile/create',

  // rooms
  podCreate: '/pod/create',
  podMembers: '/rooms/:roomId/members',
  podUpdate: '/rooms/:roomId/update',
  podView: '/rooms/:roomId',
  podQr: '/rooms/:roomId/check-in-code',
  podCheckIn: '/rooms/:roomId/check-in',
  roomCreateEvent: '/rooms/:roomId/events/create',
  roomEditEvent: '/rooms/:roomId/events/:eventId/update',
  roomViewEvent: '/rooms/:roomId/events/:eventId',
  pods: '/pods',
  schedules: '/schedules',
  podsSearch: '/search',
  /**
   * make payments for selected pod in redux
   */
  podPayment: '/pod/payment',

  // sessions
  eventCreate: '/events/create',
  eventEdit: '/events/:eventId/update',
  eventMembers: '/events/:eventId/members',
  eventView: '/events/:eventId',
  eventQr: '/events/:eventId/check-in-code',
  eventCheckIn: '/events/:eventId/check-in',
  sessions: '/rooms/:roomId/sessions',

  // notifications
  notifications: '/notifications',

  // users
  users: '/users',
  user: '/users/:userId',

  loginDistrict: '/login-district',

  /**
   * implements stripe refresh_url
   *
   * This must match server-side config StripePay::AccountCreationRefreshUrl
   * */
  profileRefresh: '/payment-refresh',
  /**
   * users are redirected here after successful payments
   *
   * This must match server-side config StripePay::PaymentSuccessUrl
   */
  paymentSuccess: '/payment-success',
  /**
   * users are redirected here after cancelling a payment
   *
   * This must match server-side config StripePay::PaymentCancelUrl
   */
  paymentCancel: '/payment-cancel',
  resumeBuilder: '/resume-builder',
};
/* eslint-enable sort-keys-fix/sort-keys-fix */

/* eslint-disable sort-keys-fix/sort-keys-fix */
/** Returns type safe route that will inform the user when route parameters are required. */
export const Route = {
  index: generatePathNoParams(RawRoute.index),

  // authentication
  login: generatePathNoParams(RawRoute.login),
  logout: generatePathNoParams(RawRoute.logout),
  verify: generatePathNoParams(RawRoute.verify),
  home: generatePathNoParams(RawRoute.home),

  // admin
  admin: generatePathNoParams(RawRoute.admin),
  adminSchoolMembership: generatePathWithParams(RawRoute.adminSchoolMembership),

  organizations: generatePathNoParams(RawRoute.organizations),
  organization: generatePathWithParams(RawRoute.organization),

  // profile
  profile: generatePathNoParams(RawRoute.profile),
  profileCreate: generatePathNoParams(RawRoute.profileCreate),

  // rooms
  podCreate: generatePathNoParams(RawRoute.podCreate),
  podMembers: generatePathWithParams(RawRoute.podMembers),
  podUpdate: generatePathWithParams(RawRoute.podUpdate),
  podView: generatePathWithParams(RawRoute.podView),
  podQr: generatePathWithParams(RawRoute.podQr),
  podCheckIn: generatePathWithParams(RawRoute.podCheckIn),
  roomCreateEvent: generatePathWithParams(RawRoute.roomCreateEvent),
  roomEditEvent: generatePathWithParams(RawRoute.roomEditEvent),
  roomViewEvent: generatePathWithParams(RawRoute.roomViewEvent),
  pods: generatePathNoParams(RawRoute.pods),
  schedules: generatePathNoParams(RawRoute.schedules),
  podsSearch: generatePathNoParams(RawRoute.podsSearch),
  /**
   * make payments for selected pod in redux
   */
  podPayment: generatePathNoParams(RawRoute.podPayment),

  // sessions
  eventCreate: generatePathNoParams(RawRoute.eventCreate),
  eventEdit: generatePathWithParams(RawRoute.eventEdit),
  eventMembers: generatePathWithParams(RawRoute.eventMembers),
  eventView: generatePathWithParams(RawRoute.eventView),
  eventQr: generatePathWithParams(RawRoute.eventQr),
  eventCheckIn: generatePathWithParams(RawRoute.eventCheckIn),
  sessions: generatePathWithParams(RawRoute.sessions),

  // notifications
  notifications: generatePathNoParams(RawRoute.notifications),

  loginDistrict: generatePathNoParams(RawRoute.loginDistrict),

  // users
  users: generatePathNoParams(RawRoute.users),
  user: generatePathWithParams(RawRoute.user),
  /**
   * implements stripe refresh_url
   *
   * This must match server-side config StripePay::AccountCreationRefreshUrl
   * */
  profileRefresh: generatePathNoParams(RawRoute.profileRefresh),
  /**
   * users are redirected here after successful payments
   *
   * This must match server-side config StripePay::PaymentSuccessUrl
   */
  paymentSuccess: generatePathNoParams(RawRoute.paymentSuccess),
  /**
   * users are redirected here after cancelling a payment
   *
   * This must match server-side config StripePay::PaymentCancelUrl
   */
  paymentCancel: generatePathNoParams(RawRoute.paymentCancel),
  resumeBuilder: generatePathNoParams(RawRoute.resumeBuilder),
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
