export type RenderStatesProps<T> = Record<
  Exclude<Utils.QueryStatus, 'success'> | 'empty',
  React.ReactNode | (() => React.ReactNode)
> & {
  data: T;
  status: Utils.QueryStatus;
  success: (data: NonNullable<T>) => React.ReactNode;
};

/** Common query-status-based render states */
export function RenderStates<T>({ data, status, empty, error, pending, success }: RenderStatesProps<T>) {
  switch (status) {
    case 'error':
      return typeof error === 'function' ? error() : error;
    case 'pending':
      return typeof pending === 'function' ? pending() : pending;
    case 'success': {
      if (!data) {
        return typeof empty === 'function' ? empty() : empty;
      }

      return success(data);
    }
    default:
      status satisfies never;
  }
}
