import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { addFacilitator, addMember, rejectMember, removeFacilitator, removeMember } from '../../actions/events';
import { Member, RightSidebar } from '../../cc-ui';
import { useSelector } from '../../hooks';
import { useSessionMemberManagementQuery, useSessionNameQuery } from '../../services/graphql/generated/react-query';
import MemberManagement, { MemberManagementContainerProps } from '../Shared/MemberManagement';

const tHeading = 'Member Management';
const tTitle = 'Remove from $name';
const tBody =
  'Removing a member from $name cannot be undone. The member will have to request membership and await the approval process if they want to rejoin this session.';
const tActionText = 'Remove From Event';
const tThisSession = 'This Event';

const EventMembers = () => {
  const { roomId, eventId } = useParams();
  const isOwner = useSelector((state) => (state.app.selectedPod?.ownerId ?? 0) === (state.user?.id ?? 1));
  const {
    isLoading,
    data: name,
    error,
  } = useSessionNameQuery({ eventId: eventId! }, { select: (data) => data?.session?.name });
  const [selectedMember, setSelectedMember] = useState<Member | undefined>();

  if (isLoading || error) {
    return null;
  }

  const safeName = name ?? tThisSession;
  const body = { eventId: eventId!, roomId: roomId! };

  const mgmtProps: MemberManagementContainerProps = {
    addFacilitatorRequestActionCreator: (userId) => addFacilitator({ userId, ...body }),
    approveRequestActionCreator: (userId) => addMember({ userId, ...body }),
    eventId,
    heading: tHeading,
    isOwner,
    onClick: setSelectedMember,
    query: () =>
      useSessionMemberManagementQuery(
        { id: eventId! },
        {
          select: (data) => {
            const facilitators = data.session?.facilitators.map(({ id }) => id) ?? [];
            return {
              ...data.session,
              members:
                data.session?.members
                  .filter((member) => member.id !== data?.session?.owner.id)
                  .map((member) => ({
                    ...member,
                    isFacilitator: facilitators.includes(member.id),
                  })) ?? [],
              requests: data.session?.requests.map((request) => ({ ...request, isVetted: false })) ?? [],
            };
          },
        }
      ),
    rejectRequestActionCreator: (userId) => rejectMember({ userId, ...body }),
    removeDialog: {
      actionText: tActionText,
      body: tBody.replace('$name', safeName),
      title: tTitle.replace('$name', safeName),
    },
    removeFacilitatorRequestActionCreator: (userId) => removeFacilitator({ userId, ...body }),
    removeMemberActionCreator: (userId) => removeMember({ userId, ...body }),
    roomId,
    subHeading: safeName,
  };

  return (
    <>
      <MemberManagement {...mgmtProps} />
      <RightSidebar
        headingText={selectedMember ? `${selectedMember.firstName} ${selectedMember.lastName}` : ''}
        label={() => null /* don't render anything to open the sidebar */}
        onClose={() => setSelectedMember(undefined)}
        open={selectedMember !== undefined}
      >
        User history coming soon!
      </RightSidebar>
    </>
  );
};

export default EventMembers;
