import { Box, Typography } from '@mui/material';
import { type PartialDeep } from 'type-fest';

import { TextField } from '~ui/atoms/Form';
import { REGEX_EMAIL } from '~utils/regex';

import { ResumeWizardNavigation } from '../ResumeWizardNavigation';
import { TypedFormSpy } from './utils';

export type PersonalInformationFieldValues = {
  personalInformation: {
    email: string;
    name: string;
    phone: string;
    linkedIn: string;
    location: string;
  };
};

export function getFieldName(
  name: `${keyof PersonalInformationFieldValues}.${keyof PersonalInformationFieldValues['personalInformation']}`
) {
  return name;
}

export type PersonalInformationBaseProps = {
  initialValues?: PartialDeep<PersonalInformationFieldValues>['personalInformation'];
};
export type PersonalInformationSlotProps = { slotProps?: object };
export type PersonalInformationProps = PersonalInformationBaseProps & PersonalInformationSlotProps;

export function PersonalInformation({ initialValues }: PersonalInformationProps) {
  return (
    <Box data-testid="Personal Information Form" display="grid" gap="1.5rem">
      <TextField
        initialValue={initialValues?.name}
        name={getFieldName('personalInformation.name')}
        required
        textFieldProps={{
          'data-testid': getFieldName('personalInformation.name'),
          label: 'Full Name',
          variant: 'filled',
        }}
        validate={function (value, _, meta) {
          if (meta?.initial === undefined && !meta?.modified) {
            return;
          }

          if (!value) {
            return 'Name required';
          }
        }}
      />
      {/* ?: replace with mapbox autocomplete */}
      <TextField
        initialValue={initialValues?.location}
        name={getFieldName('personalInformation.location')}
        required
        textFieldProps={{
          'data-testid': getFieldName('personalInformation.location'),
          label: 'Location',
          variant: 'filled',
        }}
        validate={function (value, _, meta) {
          if (meta?.initial === undefined && !meta?.modified) {
            return;
          }

          if (!value) {
            return 'Location required';
          }

          if (value.length < 5) {
            return 'Location must be of format "City, State" and be at least 5 characters, e.g. "Miami, FL"';
          }

          if (!(value.toLowerCase().indexOf(',') > 0)) {
            return 'Location must be of format "City, State", e.g. "Miami, FL"';
          }
        }}
      />
      <TextField
        initialValue={initialValues?.phone}
        name={getFieldName('personalInformation.phone')}
        required
        textFieldProps={{ 'data-testid': getFieldName('personalInformation.phone'), label: 'Phone', variant: 'filled' }}
        validate={function (value, _, meta) {
          if (meta?.initial === undefined && !meta?.modified) {
            return;
          }

          if (!value) {
            return 'Phone number required';
          }

          const digitsMatch = value.match(/\d/g);
          if (value.length > 0 && !digitsMatch) {
            return 'Phone number must include at least 10 digits, e.g. 5558675309';
          }

          if (digitsMatch) {
            if (value.startsWith('+') && !value.match(/\s/)) {
              return 'Phone number requires a space after the country code, e.g. +0049 30128675309';
            }

            const digits = digitsMatch.join('');
            if (digits.length < 10) {
              return 'Phone number must include at least 10 digits, e.g. 5558675309';
            }

            if (digits.length > 15) {
              return 'Phone number must include fewer than 16 digits, e.g. +0049 (30) 128675309';
            }
          }
        }}
      />
      <TextField
        initialValue={initialValues?.email}
        name={getFieldName('personalInformation.email')}
        required
        textFieldProps={{ 'data-testid': getFieldName('personalInformation.email'), label: 'Email', variant: 'filled' }}
        validate={function (value, _, meta) {
          if (meta?.initial === undefined && !meta?.modified) {
            return;
          }

          if (!value) {
            return 'Email required';
          }

          if (value.length < 5) {
            return 'Email must contain at least 5 characters, e.g. "a@b.c"';
          }

          if (!value.match(REGEX_EMAIL)) {
            return 'Email must be of format "a@b.c"';
          }
        }}
      />
      <TextField
        initialValue={initialValues?.linkedIn}
        name={getFieldName('personalInformation.linkedIn')}
        textFieldProps={{
          'data-testid': getFieldName('personalInformation.linkedIn'),
          label: 'LinkedIn Profile',
          variant: 'filled',
        }}
        validate={function (value, _, meta) {
          if (meta?.initial === undefined && !meta?.modified) {
            return;
          }

          if (value) {
            if (
              !value.startsWith('https://www.linkedin.com/in/') &&
              !value.startsWith('https://linkedin.com/in/') &&
              !value.startsWith('www.linkedin.com/in/') &&
              !value.startsWith('linkedin.com/in/')
            ) {
              return 'LinkedIn Profile URL must start with "https://www.linkedin.com/in/", "https://linkedin.com/in/", "www.linkedin.com/in/", or "linkedin.com/in/"';
            }

            if (!value.match(/\/in\/\w+/)) {
              return 'LinkedIn Profile URL must include username, e.g. "linkedin.com/in/my-cool-username"';
            }
          }
        }}
      />
      <TypedFormSpy>
        {({ values, hasValidationErrors, form: { submit: onClick }, submitError }) => {
          const disabled =
            !values.personalInformation ||
            !values.personalInformation.name ||
            !values.personalInformation.email ||
            !values.personalInformation.phone ||
            !values.personalInformation.location ||
            hasValidationErrors;

          return (
            <>
              <Typography color="error.main" variant="overline">
                {submitError}
              </Typography>
              <ResumeWizardNavigation slotProps={{ next: { disabled, onClick } }} />
            </>
          );
        }}
      </TypedFormSpy>
    </Box>
  );
}
