import { Mail as MailIcon, Phone as PhoneIcon } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';

interface LoginTabsProps {
  activeTab: 'email' | 'phone';
  onTabChange: (tab: 'email' | 'phone') => void;
}

export const LoginTabs = ({ activeTab, onTabChange }: LoginTabsProps) => {
  const handleChange = (_: React.SyntheticEvent, newValue: 'email' | 'phone') => {
    onTabChange(newValue);
  };

  return (
    <Tabs
      onChange={handleChange}
      sx={{
        '& .MuiTab-root': {
          minHeight: '48px',
          textTransform: 'none',
        },
        mb: 4,
      }}
      value={activeTab}
      variant="fullWidth"
    >
      <Tab
        data-cy="loginTabEmail"
        icon={<MailIcon sx={{ fontSize: '1.5rem' }} />}
        iconPosition="start"
        label="Email"
        value="email"
      />
      <Tab
        data-cy="loginTabPhone"
        icon={<PhoneIcon sx={{ fontSize: '1.5rem' }} />}
        iconPosition="start"
        label="Phone"
        value="phone"
      />
    </Tabs>
  );
};
