/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, BoxProps } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridToolbarQuickFilter,
  GridToolbarQuickFilterProps,
} from '@mui/x-data-grid-pro';
import { memo, useRef } from 'react';

import { PrintWidget } from '../../atoms/DataGrid/Widgets/PrintWidget';
import {
  EmptyState,
  EmptyStateProps,
  ErrorState,
  ErrorStateProps,
  ListLoadingState,
  ListLoadingStateProps,
} from '../../atoms/SectionStates';

const MIN_HEIGHT: React.CSSProperties['height'] = '372px';
const MAX_HEIGHT: React.CSSProperties['height'] = '813px';

const QuickFilter = (props: GridToolbarQuickFilterProps) => {
  return (
    <Box
      alignItems="end"
      display="grid"
      gap="2rem"
      gridAutoColumns="minmax(0, 1fr)"
      gridAutoFlow="column"
      padding="1rem 1rem 1.5rem"
    >
      <GridToolbarQuickFilter {...props} />
      {!props.children && <i />}
      <Box display="grid" gap="1rem" gridAutoColumns="minmax(0, auto)" gridAutoFlow="column" justifyContent="end">
        {props.children}
        <PrintWidget slotProps={{ button: { edge: 'end' } }} />
      </Box>
    </Box>
  );
};

const sharedSlotProps: ListLoadingStateProps['slotProps'] = {
  container: {
    height: `calc(${MIN_HEIGHT} - 2px)`,
    sx: {
      border: (theme) => `1px solid ${theme.palette.crowdCoursing.GREY[400]?.main ?? 'transparent'}`,
      borderRadius: '0.25rem',
    },
  },
};

export type RowData<T extends Record<string, any>> = T & { rowId: string };

export type ColumnData<T extends Record<string, any>> = Omit<GridColDef<RowData<T>>, 'field'> & {
  field: keyof RowData<T>;
};

export interface DataGridProps<T extends Record<string, any>> {
  /** Column data. This should be memoized. */
  columns?: ColumnData<T>[];
  headerWidgets?: React.ReactNode;
  /** Row data. This should be memoized. */
  rows?: RowData<T>[];
  slotProps?: {
    container?: BoxProps;
    dataGrid?: Omit<DataGridProProps<T>, 'columns' | 'rows'>;
    empty?: EmptyStateProps;
    error?: ErrorStateProps;
    loading?: ListLoadingStateProps;
  };
  slots?: {
    empty?: React.ReactNode;
    error?: React.ReactNode;
    loading?: React.ReactNode;
  };
  status?: Utils.QueryStatus;
}

function NoRowsOverlayWrapper(props?: EmptyStateProps) {
  return function NoRowsOverlay() {
    return <EmptyState slotProps={sharedSlotProps} {...props} />;
  };
}

export const DataGrid = <T extends Record<string, any>>({
  columns = [],
  headerWidgets,
  rows = [],
  slotProps,
  slots,
  status = 'success',
}: DataGridProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);

  const noRowsOverlay = memo(
    NoRowsOverlayWrapper({
      ...slotProps?.empty,
      slotProps: {
        ...slotProps?.empty?.slotProps,
        container: {
          ...slotProps?.empty?.slotProps?.container,
          height: '100%',
        },
      },
    })
  );

  return (
    <Box
      {...slotProps?.container}
      maxHeight={ref?.current?.style.maxHeight ?? MAX_HEIGHT}
      minHeight={ref?.current?.style.minHeight ?? MIN_HEIGHT}
    >
      {status === 'pending' &&
        (slots?.loading ?? <ListLoadingState count={7} slotProps={sharedSlotProps} {...slotProps?.loading} />)}
      {status === 'error' && (slots?.error ?? <ErrorState slotProps={sharedSlotProps} {...slotProps?.error} />)}
      {status === 'success' && (
        <DataGridPro
          disableColumnFilter
          filterMode="client"
          getRowHeight={() => 'auto'}
          getRowId={(row) => row.rowId}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          pagination
          {...slotProps?.dataGrid}
          // @ts-expect-error Unsure how to enforce record's key will always be a string
          columns={columns}
          initialState={{
            ...slotProps?.dataGrid?.initialState,
            pagination: {
              ...slotProps?.dataGrid?.initialState?.pagination,
              paginationModel: { pageSize: 5, ...slotProps?.dataGrid?.initialState?.pagination?.paginationModel },
            },
          }}
          ref={ref}
          rows={rows}
          slotProps={{
            ...slotProps?.dataGrid?.slotProps,
            footer: {
              ...slotProps?.dataGrid?.slotProps?.footer,
              sx: { display: 'flex', justifyContent: 'center', ...slotProps?.dataGrid?.slotProps?.footer?.sx },
            },
            toolbar: {
              children: headerWidgets,
              showQuickFilter: true,
              ...slotProps?.dataGrid?.slotProps?.toolbar,
              quickFilterProps: {
                debounceMs: 333,
                ...slotProps?.dataGrid?.slotProps?.toolbar?.quickFilterProps,
              },
              style: { paddingBottom: 0, ...slotProps?.dataGrid?.slotProps?.toolbar?.style },
            },
          }}
          slots={slots ? {} : { toolbar: QuickFilter, ...slotProps?.dataGrid?.slots, noRowsOverlay }}
          sx={{
            ' .MuiDataGrid-cell': { alignItems: 'baseline', padding: '0.5rem' },
            ' .MuiDataGrid-columnHeader': {
              ' .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
              '&:focus-within': { outline: 'unset' },
            },
            ...(rows.length
              ? {}
              : {
                  ' .MuiDataGrid-overlayWrapper': { display: 'inline-grid !important', width: '100%' },
                  ' .MuiDataGrid-virtualScroller': { overflow: 'hidden !important' },
                }),
            height: 'auto',
            maxHeight: MAX_HEIGHT,
            minHeight: MIN_HEIGHT,
            ...slotProps?.dataGrid?.sx,
          }}
        />
      )}
    </Box>
  );
};
