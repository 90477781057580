import { ResumeBuilderTemplate } from '~ui/templates/ResumeBuilderTemplate/ResumeBuilderTemplate';
import { asyncify } from '~utils/asyncify';

import { useResumeBuilder } from './useResumeBuilder';

export function ResumeBuilder() {
  const { resumeBuilderTemplateProps: props } = useResumeBuilder();
  return <ResumeBuilderTemplate {...props} />;
}

export const ResumeBuilderAsync = asyncify(ResumeBuilder);
